import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import styled from "styled-components";

import Disqus from "disqus-react";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const BlogBlock = styled.div`
  // border: red 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  justify-content: center;
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 300px;
  height: auto;
  margin: 10px auto;
  display: block;
`;

const BlogPageKorean = () => {
  const [post, setPost] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v2/pages/?type=blog.KoreanBlogIndexPage&fields=description,thumbnail,body&id=` +
          id
      )
      .then((response) => {
        const blogPost = response.data.items[0];
        const body = blogPost.body;

        // Loop through each item in the body array
        const images = body
          .filter((item) => item.type === "image")
          .map((item) => item.value);
        const imageRequests = images.map((imageId) => {
          return axios.get(
            `${process.env.REACT_APP_API_URL}/api/v2/images/${imageId}`
          );
        });

        axios
          .all(imageRequests)
          .then(
            axios.spread((...responses) => {
              const imageUrls = responses.map(
                (response) => response.data.meta.download_url
              );

              // Update the post with the image URLs
              setPost({
                ...blogPost,
                body: body.map((item) => {
                  if (item.type === "image") {
                    const index = images.indexOf(item.value);
                    item.src = imageUrls[index];
                  }
                  return item;
                }),
              });
            })
          )
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!post) {
    return (
      <h4>
        <img src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif" />
      </h4>
    );
  }

  // const url = 'https://localhost:3000/blog/'
  const url = "https://stackingtabs.com/#/blog/korean/";
  const disqusShortname = "stackingtabs";
  const disqusConfig = {
    url: `${url}${post.id}`,
    identifier: post.length > 0 ? post.id : "",
    title: post.length > 0 ? post.title : "",
  };

  return (
    <div>
      <BlogBlock>
        <Img
          src={`${process.env.REACT_APP_API_URL}${post.thumbnail.meta.download_url}`}
        />
        <h1>{post.title}</h1>
        <div>{post.description}</div>
        <div>
          {post.body.map((item, index) => {
            if (item.type === "image") {
              return (
                <Img
                  key={index}
                  src={`${process.env.REACT_APP_API_URL}${item.src}`}
                  alt={item.alt}
                />
              );
            } else if (item.type === "paragraph") {
              return (
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.value),
                  }}
                  key={index}
                />
              );
            }
          })}
        </div>
      </BlogBlock>
      <Disqus.DiscussionEmbed
        shortname={disqusShortname}
        config={disqusConfig}
      />
    </div>
  );

  // const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);
  // const [thumbnailimage, setThumbnailimage] = useState([]);
  // const [body, setBody] = useState([]);
  // const [image, setImage] = useState([]);
  // const [paragraph, setParagraph] = useState([]);
  // const { id } = useParams();

  // const retrieveBody = async () => {
  //   try {
  //     setLoading(true);
  //     const result = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/api/v2/pages/?type=blog.KoreanBlogIndexPage&fields=description,thumbnail,body&id=` +
  //         id
  //     );

  //     setData(result.data.items);
  //     setThumbnailimage(result.data.items[0].thumbnail.meta.download_url);
  //     setBody(result.data.items[0].body);
  //     // setLoading(false);

  //     let urls = [];
  //     let html = [];
  //     for (let i = 0; i < body.length; i++) {
  //       if (body[i].type === "image") {
  //         const results = await axios.get(
  //           `${process.env.REACT_APP_API_URL}/api/v2/images/` + body[i].value
  //         );
  //         // .then((results) => {
  //         //     setImage(results.data.meta.download_url);
  //         //   });
  //         urls.push(results.data.meta.download_url);
  //         console.log("urls1", urls);
  //       } else if (body[i].type === "paragraph") {
  //         html.push(
  //           <div key={body[i].id}>
  //             <div
  //               dangerouslySetInnerHTML={{
  //                 __html: DOMPurify.sanitize(body[i].value),
  //               }}
  //             />
  //           </div>
  //         );
  //       }
  //     }
  //     setImage(urls);
  //     setParagraph(html);
  //     setLoading(false);
  //     console.log("urls2", urls);
  //     console.log("Paragraph", paragraph);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   retrieveBody();
  // }, [body.length]);

  // function Paragraph({ value }) {
  //   return <p dangerouslySetInnerHTML={{ __html: value }} />;
  // }

  // function Image() {
  //   return (
  //     <div>
  //       {image.map((imageUrl, index) => (
  //         <Img
  //           key={index}
  //           src={`${process.env.REACT_APP_API_URL}/${imageUrl}`}
  //           alt=""
  //         />
  //       ))}
  //     </div>
  //   );
  // }

  // function renderBody() {
  //   return body.map((item) => {
  //     if (item.type === "paragraph") {
  //       return <Paragraph key={item.id} value={item.value} />;
  //     } else if (item.type === "image") {
  //       return <Image key={item.id} value={item.value} />;
  //     } else {
  //       return null;
  //     }
  //   });
  // }

  // // function Image() {
  // //   return <Img src={`${process.env.REACT_APP_API_URL}/${image}`} alt="" />;
  // // }

  // // function renderBody() {
  // //   return body.map((item) => {
  // //     switch (item.type) {
  // //       case "paragraph":
  // //         return <Paragraph key={item.id} value={item.value} />;
  // //       case "image":
  // //         return <Image key={item.id} value={item.value} />;
  // //       default:
  // //         return null;
  // //     }
  // //   });
  // // }

  // if (loading) {
  //   return (
  //     <h4>
  //       <img src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif" />
  //     </h4>
  //   );
  // }

  // // See links here: https://stackingtabs.disqus.com/admin/discussions/
  // // Moderate here: https://stackingtabs.disqus.com/admin/moderate/pending/

  // // const url = 'https://localhost:3000/blog/'
  // const url = "https://blog.stackingtabs.com/#/blog/";
  // const disqusShortname = "stackingtabs";
  // const disqusConfig = {
  //   url: `${url}${id}`,
  //   identifier: data.length > 0 ? data[0].id : "",
  //   title: data.length > 0 ? data[0].title : "",
  // };

  // // console.log("data.id:", data.id);
  // // console.log("data.title:", data.title);
  // // console.log("disqusConfig:", disqusConfig);

  // return (
  //   <div className="container">
  //     <BlogBlock>
  //       {thumbnailimage && (
  //         <Img
  //           src={`${process.env.REACT_APP_API_URL}/${thumbnailimage}`}
  //           alt=""
  //         />
  //       )}

  //       {/* <div>
  //         {data.map((data) => (
  //           <ul key={data.id}>
  //             {data.title} <br />
  //             {data.description}
  //           </ul>
  //         ))}

  //         {paragraph.map((paragraph) => (
  //           <ul>{paragraph}</ul>
  //         ))}
  //       </div>

  //       <BlogBlock>
  //         {image.map((imageUrl, index) => (
  //           <Img
  //             key={index}
  //             src={`${process.env.REACT_APP_API_URL}/${imageUrl}`}
  //             alt=""
  //           />
  //         ))}
  //       </BlogBlock> */}

  //       <div></div>

  //       {renderBody()}

  //       {/* <div>
  //         {body.map((item) => {
  //           switch (item.type) {
  //             case "paragraph":
  //               return (
  //                 <div>
  //                   {paragraph.map((paragraph) => (
  //                     <ul>{paragraph}</ul>
  //                   ))}
  //                 </div>
  //               );
  //             case "image":
  //               return (
  //                 <Img
  //                   src={`${process.env.REACT_APP_API_URL}/${image}`}
  //                   alt=""
  //                 />
  //               );
  //             default:
  //               return null;
  //           }
  //         })}
  //       </div> */}

  //       {/* <div>
  //         {body.map((item) => {
  //           switch (item.type) {
  //             case "paragraph":
  //               return <Paragraph key={item.id} value={item.value} />;
  //             case "image":
  //               return <Image key={item.id} value={item.value} />;
  //             default:
  //               return null;
  //           }
  //         })}
  //       </div> */}

  //       {/* <div>{renderBody()}</div> */}

  //       {/* If there is no image, show nothing
  //       <Img src={`${process.env.REACT_APP_API_URL}/${image}`} alt="" />

  //       <div>
  //         {paragraph.map((paragraph) => (
  //           <ul>{paragraph}</ul>
  //         ))}
  //       </div> */}
  //     </BlogBlock>

  //     <Disqus.DiscussionEmbed
  //       shortname={disqusShortname}
  //       config={disqusConfig}
  //     />
  //   </div>
  // );
};

export default BlogPageKorean;
