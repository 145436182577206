import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD5Ighvj_0ac8RRtlgM8utkIb8AkSsboLM",
  authDomain: "wagtail-firebase.firebaseapp.com",
  // authDomain: "auth.stackingtabs.com",
  projectId: "wagtail-firebase",
  storageBucket: "wagtail-firebase.appspot.com",
  messagingSenderId: "466249529213",
  appId: "1:466249529213:web:af73436ab6c7d7f478be14",
  measurementId: "G-H4BY1HX2DX",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
