import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { devices } from "./Device";
import GridList from "./GridList";
import SearchContext from "../contexts/SearchContext";

// import AppMockup from '../assets/images/stacking-tabs-app-mockup.svg';
import { ReactComponent as AppMockup } from "../assets/images/stacking-tabs-app-mockup.svg";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const StyledAppMockup = styled(AppMockup)`
  max-width: 600px;
  display: block;
  margin: 0 auto;
`;

const BlogBlock = styled.div`
  // border: 1px green solid;
  // background-color: yellow;
  display: flex;
  justify-content: center;
`;

const BlogCard = styled.div`
  border: 1px cyan solid;
  //   background-color: red;
  display: flex;
  flex-direction: ${(props = true) => (props.isGrid ? "column" : "row")};
  padding: 10px;
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  box-shadow: 2px 3px 36px 8px rgba(0, 0, 0, 0.1);
  transition-duration: 1s;
  transform-origin: left;
  align-items: center;
  font-size: 1.2rem;
  text-decoration: none;
  // width: 25vw;
  // width: calc(100% - 3vw);
  // height: 25vw;
  font-size: 0.6rem;
  // margin-right: 10px;
  width: ${(props = true) => (props.isGrid ? "25vw" : "80vw")};
  height: ${(props = true) => (props.isGrid ? "40vw" : "30vw")};
  text-align: center;

  &:hover {
    background: rgba(89, 153, 173, 0.52);
    background: linear-gradient(
      90deg,
      rgba(53, 54, 58, 1) 0%,
      rgba(89, 153, 173, 1) 50%,
      rgba(53, 54, 58, 1) 100%
    );
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10.7px);
    -webkit-backdrop-filter: blur(10.7px);
    border: 1px solid rgba(89, 153, 173, 0.79);
  }

  @media screen and ${devices.laptop} {
    // width: 300px;
    // height: 300px;
    width: ${(props = true) => (props.isGrid ? "300px" : "50vw")};
    height: ${(props = true) => (props.isGrid ? "300px" : "25vw")};
    min-height: ${(props = true) => (props.isGrid ? "300px" : "10vw")};
    max-height: ${(props = true) => (props.isGrid ? "300px" : "10vw")};
    font-size: 1.2rem;

    &:hover {
      box-shadow: 2px 3px 36px 8px rgba(0, 0, 0, 0.4);
      // width: 40vw;
      // height: 100%;
      // width: ${(props = true) =>
        props.isGrid ? "40vw" : "50vw"}; //no more enlarge on hover
      // height: ${(props = true) =>
        props.isGrid ? "100%" : "100%"}; //no more enlarge on hover
      max-height: 80vw;
      z-index: 2;
    }
  }
`;

const BlogImage = styled.img`
  margin: 5px;
  border-radius: 5px;
  height: ${(props) => (props.isGrid ? "auto" : "100%")};
  min-height: calc(
    100% - 500px
  ); /* subtract the height of the title and description */
  max-width: 300px;
  object-fit: ${(props) =>
    props.isGrid ? "cover" : "contain"}; /* use contain for list view */
  margin-top: 5px;

  @media (max-width: 1080px) {
    max-width: 150px;
  }
`;

const linkStyle = {
  textDecoration: "none",
  color: "#5999ad",
};

const Grid = styled.div`
  // background-color: blue;
  display: ${(props = true) => (props.isGrid ? "grid" : "block")};
  grid-template-columns: auto auto auto;
  transition-duration: 0.1s;
`;

const List = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 100%;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: calc(12px + 1vw);
  text-align: center;
  margin: 0 auto;
  max-width: 80%;
  line-height: 1.8rem;

  @media (max-width: 768px) {
    font-size: calc(10px + 2vw);
    line-height: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: calc(8px + 2vw);
    line-height: 1rem;
  }
`;

const Description = styled.p`
  font-size: 0.8rem;
  text-align: center;
  margin: 0 auto;
  max-width: 80%;
  line-height: 0.8rem;

  @media (max-width: 768px) {
    /* Desktop styles */
    font-size: 0.8rem;
    line-height: 0.8rem;
  }

  @media (max-width: 480px) {
    /* Mobile styles */
    font-size: 0.6rem;
    line-height: 0.6rem;
  }
`;

const BlogListDev = () => {
  //   const [blog, setBlog] = useContext(SearchContext);
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isGrid, setIsGrid] = useState(true);

  const handleGrid = () => {
    setIsGrid(true);
  };

  const handleList = () => {
    setIsGrid(false);
  };

  useEffect(() => {
    const fetchBlogPosts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v2/pages/?child_of=5&type=blog.Blog&fields=description,thumbnail&order=-first_published_at`
        );
        setBlog(response.data.items);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlogPosts();
  }, [blog.length]);

  return (
    <div>
      <a href="../assets/downloads/apk-version-1.apk" download>
        <StyledAppMockup alt="app-mockup" />
        {/* Download APK */}
      </a>

      <GridList setIsGrid={setIsGrid} />
      <BlogBlock>
        <Grid isGrid={isGrid}>
          {blog.map((blog) => (
            <List key={blog.id}>
              <Link to={`/blog/dev/${blog.id}`} style={linkStyle}>
                <BlogCard isGrid={isGrid}>
                  <Content>
                    <Title>{blog.title}</Title>
                    <br />
                    <Description>{blog.description}</Description>
                  </Content>
                  <BlogImage
                    src={`${process.env.REACT_APP_API_URL}${blog.thumbnail.meta.download_url}`}
                    key={blog.id}
                    alt="thumbnail-image"
                  />
                </BlogCard>
              </Link>
            </List>
          ))}
        </Grid>
      </BlogBlock>
    </div>
  );
};

export default BlogListDev;
