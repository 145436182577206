import React, { useState, useEffect, useContext } from "react";
// import SearchContext from "../contexts/SearchContext";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import styled from "styled-components";

import Disqus from "disqus-react";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const BlogBlock = styled.div`
  // border: red 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 300px;
  height: auto;
`;

const BlogPageDev = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [thumbnailimage, setThumbnailimage] = useState([]);
  const [body, setBody] = useState([]);
  const [image, setImage] = useState([]);
  const [paragraph, setParagraph] = useState([]);
  const { id } = useParams();

  const retrieveBody = async () => {
    try {
      setLoading(true);
      const result = await axios.get(
        // `${process.env.REACT_APP_API_URL}/api/v2/pages/?type=blog.Blog&fields=description,thumbnail,body&id=` +
        //   id
        `${process.env.REACT_APP_API_URL}/api/v2/pages/?type=blog.DevBlogIndexPage&fields=description,thumbnail,body&id=` +
          id
      );

      setData(result.data.items);
      setThumbnailimage(result.data.items[0].thumbnail.meta.download_url);
      setBody(result.data.items[0].body);
      setLoading(false);

      let html = [];
      for (let i = 0; i < body.length; i++) {
        if (body[i].type === "image") {
          const results = await axios
            .get(
              `${process.env.REACT_APP_API_URL}/api/v2/images/` + body[i].value
            )
            .then((results) => {
              setImage(results.data.meta.download_url);
            });
        } else if (body[i].type === "paragraph") {
          html.push(
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(body[i].value),
                }}
              />
            </div>
          );
          setParagraph(html);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    retrieveBody();
  }, [body.length]);

  if (loading) {
    return (
      <h4>
        <img src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif" />
      </h4>
    );
  }

  // See links here: https://stackingtabs.disqus.com/admin/discussions/
  // Moderate here: https://stackingtabs.disqus.com/admin/moderate/pending/

  // const url = 'https://localhost:3000/blog/'
  //   const url = "https://blog.stackingtabs.com/#/blog/";
  const url = "https://stackingtabs.com/#/dev/";
  const disqusShortname = "stackingtabs";
  const disqusConfig = {
    url: `${url}${id}`,
    identifier: data.length > 0 ? data[0].id : "",
    title: data.length > 0 ? data[0].title : "",
  };

  // console.log("data.id:", data.id);
  // console.log("data.title:", data.title);
  // console.log("disqusConfig:", disqusConfig);

  return (
    <div className="container">
      <BlogBlock>
        {thumbnailimage && (
          <Img
            src={`${process.env.REACT_APP_API_URL}/${thumbnailimage}`}
            alt=""
          />
        )}

        <div>
          {data.map((data) => (
            <ul key={data.id}>
              {data.title} <br />
              {data.description}
            </ul>
          ))}
        </div>

        {/* If there is no image, show nothing */}
        <Img src={`${process.env.REACT_APP_API_URL}/${image}`} alt="" />

        <div>
          {paragraph.map((paragraph) => (
            <ul>{paragraph}</ul>
          ))}
        </div>
      </BlogBlock>

      <Disqus.DiscussionEmbed
        shortname={disqusShortname}
        config={disqusConfig}
      />
    </div>
  );
};

export default BlogPageDev;
