import React, { useState } from "react";
import useDarkMode from "use-dark-mode";
import styled from "styled-components";
import { HiMoon, HiSun } from "react-icons/hi";

const DarkModeToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  font-size: 1.2em;
  background: none;
  border: none;
  color: ${(props) => (props.isMoonIcon ? "#ffe600" : "#5999ad")};
  // color: #5999ad;
  // color: ${(props) => props.theme.textColor || "#5999ad"};
  cursor: pointer;
  transition: color 0.3s ease;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #0062cc;
  }
`;

const DarkModeToggle = (props) => {
  const [isMoonIcon, setIsMoonIcon] = useState(false);
  const darkMode = useDarkMode(true);
  const { toggleTheme } = props;

  const handleToggle = () => {
    setIsMoonIcon(!isMoonIcon);
    toggleTheme();

    if (darkMode.value === true) {
      darkMode.disable();
    } else {
      darkMode.enable();
    }
  };

  return (
    <DarkModeToggleContainer>
      <Button type="button" isMoonIcon={isMoonIcon} onClick={handleToggle}>
        {/* {isMoonIcon ? '☀' : '☾'} */}
        {isMoonIcon ? <HiSun /> : <HiMoon />}
      </Button>
    </DarkModeToggleContainer>
  );
};

export default DarkModeToggle;
