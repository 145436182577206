import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "./FirebaseUtils";
import styled from "styled-components";
import { toast } from "react-toastify";

const Button = styled.button`
  background-color: #5999ad;
  background: linear-gradient(160deg, #5999ad 0%, #306d7c 100%);
  color: #333;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 35px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  }

  &:active {
    transform: translateY(0px);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  }
`;

const SignUp = ({
  email,
  password,
  callback,
  emailInputRef,
  setShowEmailIcon,
}) => {
  const registerNewUser = async () => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      console.log(res.user);

      await sendEmailVerification(user, {
        // url: "http://localhost:3000/#/login",
        url: "https://stackingtabs.com/#/login",
      });
      await user.reload(); // Reload the user object to get the latest emailVerified status

      if (user.emailVerified) {
        let idToken = await user.getIdToken(true);
        callback(idToken);

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/auth/register`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${idToken}`,
            },
          }
        );
        const data = await response.json();
        console.log(data);
      } else {
        toast.info("Please verify your email before signing in.", {
          position: "top-center",
        });
      }
    } catch (error) {
      console.log(error);
      switch (error.code) {
        case "auth/email-already-in-use":
          toast.error("Email address is already in use", {
            position: "top-center",
          });
          break;
        case "auth/user-not-found":
          toast.error(`User with ${email} is not found`, {
            position: "top-center",
          });
          break;
        case "auth/missing-email":
        case "auth/invalid-email":
          toast.info("Please provide a valid email in the email input box", {
            position: "top-center",
          });
          emailInputRef.current.focus();
          setShowEmailIcon(true);
          break;
        case "auth/missing-password":
          toast.error("Please provide password", {
            position: "top-center",
          });
          break;
        case "auth/wrong-password":
          toast.error("Password does not match the email", {
            position: "top-center",
          });
          break;
        case "auth/weak-password":
          toast.error("Please provide a stronger password", {
            position: "top-center",
          });
          break;
        case "auth/network-request-failed":
          toast.error("Something is wrong, check your network connection", {
            position: "top-center",
          });
          break;
        default:
          console.log(error);
          toast.error("An error occurred", { position: "top-center" });
          break;
      }
    }
  };

  return (
    <>
      <Button onClick={registerNewUser}>Sign Up</Button>
    </>
  );
};

export default SignUp;
