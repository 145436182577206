// light theme
export const lightTheme = {
  colors: {
    background: "#fff",
    text: "#333",
    navbar: "#95d3ff",
    codeBackground: "#cbe9ff",
    link: "#006688",
    button: "#006688",
    toggleButtonColor: "#ffe600",
    toggleButtonSecondaryColor: "#666",
    toggleSwitchBackground: "#555",
    toggleSwitchBackgroundChecked: "#fff",
    toggleSwitchButtonBackground: "#f6f6f6",
  },
  font: {
    family:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    size: "20px",
    lineHeight: "1.3em",
    h1Size: "2rem",
    codeSize: "1em",
  },
  transition: {
    ease: "ease",
    time: "0.3s",
  },
};

// dark theme
export const darkTheme = {
  colors: {
    background: "#10171d",
    text: "#dfdfdf",
    navbar: "#1b2938",
    codeBackground: "#1b2938",
    link: "#ee09",
    button: "#ee09",
    toggleButtonColor: "#999",
    toggleButtonSecondaryColor: "lightblue",
    toggleSwitchBackground: "#555",
    toggleSwitchBackgroundChecked: "#fff",
    toggleSwitchButtonBackground: "#f6f6f6",
  },
  font: {
    family:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    size: "20px",
    lineHeight: "1.3em",
    h1Size: "2rem",
    codeSize: "1em",
  },
  transition: {
    ease: "ease",
    time: "0.3s",
  },
};
