import { useAuth } from "../../contexts/AuthContext";
import { Navigate } from "react-router-dom";
import Dashboard from "./Dashboard";

function PrivateRoute() {
  const { currentUser } = useAuth();

  // Redirect to login page if user is not logged in
  if (!currentUser) {
    return <Navigate to="/" />;
  }

  // Display email in Dashboard.js
  const email = currentUser ? currentUser.email : "";

  // Render the dashboard if user is logged in
  return <Dashboard email={email} />;
}

export default PrivateRoute;
