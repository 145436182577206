import styled, { css, keyframes } from "styled-components";

const colors = {
  blue100: "#FCFFEE",
  blue800: "#5999ad",
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const textStrokeAnimation = css`
  -webkit-text-stroke: 1px ${colors.blue100};
  color: ${colors.blue800};
  opacity: 0;
  animation: ${fadeIn} 2s ease-in-out forwards;

  @media (prefers-reduced-motion) {
    opacity: 1 !important;
    color: ${colors.blue800};
  }
`;

const LandingPageContainer = styled.div`
  text-align: center;
`;

const WelcomeHeader = styled.h1`
  font-size: 3rem;
`;

const Text1 = styled.span`
  ${textStrokeAnimation}
`;

const Text2 = styled.span`
  ${textStrokeAnimation}
  animation-delay: 0.53s;
`;

const Text3 = styled.span`
  ${textStrokeAnimation}
  animation-delay: 1.06s;
`;

const LandingPage = () => {
  return (
    <LandingPageContainer>
      <WelcomeHeader>
        <Text1>w</Text1>
        <Text2>e</Text2>
        <Text3>l</Text3>
        <Text1>c</Text1>
        <Text2>o</Text2>
        <Text3>m</Text3>
        <Text1>e</Text1>
      </WelcomeHeader>
    </LandingPageContainer>
  );
};

export default LandingPage;
