import React from "react";
import {
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "./FirebaseUtils";
import { toast } from "react-toastify";

import styled from "styled-components";

const Button = styled.button`
  background-color: #5999ad;
  background: linear-gradient(160deg, #5999ad 0%, #306d7c 100%);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 35px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  }

  &:active {
    transform: translateY(0px);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  }
`;

const SignInButton = ({ email, password, callback }) => {
  const navigate = useNavigate();

  const signInUser = async () => {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const user = res.user;

      if (!user.emailVerified) {
        await signOut(auth);
        toast.info("Please verify your email before signing in.", {
          position: "top-center",
        });
        await sendEmailVerification(user);
        return;
      }

      if (user.emailVerified) {
        let idToken = await user.getIdToken(true);
        callback(idToken);

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/auth/register`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${idToken}`,
            },
            body: JSON.stringify({}),
          }
        );
        const data = await response.json();
        console.log(data);
      }

      // if (user.emailVerified) {
      //   let idToken = await user.getIdToken(true);
      //   callback(idToken);

      //   const response = await fetch(
      //     `${process.env.REACT_APP_API_URL}/api/auth/authenticated`,
      //     {
      //       method: "GET",
      //       headers: {
      //         "Content-Type": "application/json",
      //         Authorization: `JWT ${idToken}`,
      //       },
      //       // body: JSON.stringify({}),
      //     }
      //   );
      //   const data = await response.json();
      //   console.log(data);
      // }

      // Redirect the user to the dashboard
      navigate("/dashboard");
    } catch (error) {
      switch (error.code) {
        case "auth/user-not-found":
          toast.error(`User with ${email} is not found`, {
            position: "top-center",
          });
          break;
        case "auth/invalid-email":
          toast.error("Please provide a valid email", {
            position: "top-center",
          });
          break;
        case "auth/missing-password":
          toast.error("Please provide password", {
            position: "top-center",
          });
          break;
        case "auth/wrong-password":
          toast.error("Password does not match the email", {
            position: "top-center",
          });
          break;
        case "auth/network-request-failed":
          toast.error("Something is wrong, check your network connection", {
            position: "top-center",
          });
          break;
        default:
          console.log(error);
          toast.error("An error occurred", { position: "top-center" });
          break;
      }
    }
  };

  return <Button onClick={signInUser}>Sign In</Button>;
};

export default SignInButton;

// const SignInButton = ({ email, password, callback }) => {
//   const navigate = useNavigate();

//   const signInUser = async () => {
//     try {
//       const res = await signInWithEmailAndPassword(auth, email, password);
//       const user = res.user;

//       if (!user.emailVerified) {
//         await signOut(auth);
//         toast.info("Please verify your email before signing in.", {
//           position: "top-center",
//         });
//         return;
//       }

//       let idToken = await user.getIdToken(true);
//       callback(idToken);

//       // Redirect the user to the dashboard
//       navigate("/dashboard");
//     } catch (error) {
//       switch (error.code) {
//         case "auth/user-not-found":
//           toast.error(`User with ${email} is not found`, {
//             position: "top-center",
//           });
//           break;
//         case "auth/invalid-email":
//           toast.error("Please provide a valid email", {
//             position: "top-center",
//           });
//           break;
//         case "auth/missing-password":
//           toast.error("Please provide password", {
//             position: "top-center",
//           });
//           break;
//         case "auth/wrong-password":
//           toast.error("Password does not match the email", {
//             position: "top-center",
//           });
//           break;
//         case "auth/network-request-failed":
//           toast.error("Something is wrong, check your network connection", {
//             position: "top-center",
//           });
//           break;
//         default:
//           console.log(error);
//           toast.error("An error occurred", { position: "top-center" });
//           break;
//       }
//     }
//   };

//   return <Button onClick={signInUser}>Login</Button>;
// };

// export default SignInButton;

// --------------------------------------------------------------------------

// import { signInWithEmailAndPassword, signOut } from "firebase/auth";
// import { auth } from "./FirebaseUtils";
// import styled from "styled-components";

// const Button = styled.button`
//   background-color: transparent;
//   border: none;
//   width: 100%; // fill entire width of parent container
//   height: 100%; // fill entire height of parent container
//   color: white; // button text colour
// `;

// const SignInButton = ({ email, password, callback }) => {
//   const signInUser = async () => {
//     try {
//       const res = await signInWithEmailAndPassword(auth, email, password);
//       console.log(res.user);
//       const user = res.user;

//       if (!user.emailVerified) {
//         await signOut(auth); // Sign out the user if email is not verified
//         window.alert("Please verify your email before signing in.");
//         return;
//       }

//       let idToken = await user.getIdToken(true);
//       callback(idToken);
//       // Verify and register user with backend
//       const response = await fetch("http://localhost:8000/api/register", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `JWT ${idToken}`,
//         },
//       });
//       const data = await response.json();
//       console.log(data);
//       window.alert("Signed in.");

//     } catch (e) {
//       window.alert(e.code);
//     }
//   };
//   return <Button onClick={signInUser}>Login</Button>;
// };

// export default SignInButton;
