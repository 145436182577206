// import React from "react";
// import styled from "styled-components";
// import { signOut } from "firebase/auth";
// import { auth } from "./FirebaseUtils";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

// const DashboardContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
// `;

// const Title = styled.h1`
//   font-size: 2rem;
//   margin-bottom: 1rem;
// `;

// const Subtitle = styled.h2`
//   font-size: 1.2rem;
//   margin-bottom: 2rem;
// `;

// const Button = styled.button`
//   background-color: #0077ff;
//   color: white;
//   padding: 0.5rem 1rem;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;

//   &:hover {
//     background-color: #0053ba;
//   }
// `;

// const Dashboard = ({ email }) => {
//   const navigate = useNavigate();

//   const handleSignOut = async () => {
//     await signOut(auth);
//     localStorage.removeItem("user");
//     toast.success("Signed out.", { position: "top-center" });
//     navigate("/");
//   };

//   return (
//     <DashboardContainer>
//       <Title>Welcome {email}!</Title>
//       <Subtitle>This is your dashboard</Subtitle>
//       <Button onClick={handleSignOut}>Sign Out</Button>
//     </DashboardContainer>
//   );
// };

// export default Dashboard;

// const fetchSavedBlogs = async () => {
//   try {
//     const user = auth.currentUser;
//     const userId = user ? user.uid : null;

//     if (userId) {
//       const idToken = await user.getIdToken(true);
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/blog/dashboard/`,
//         {
//           headers: {
//             Authorization: `JWT ${idToken}`,
//           },
//         }
//       );

//       setSavedBlogs(response.data.saved_blogs);
//     }
//   } catch (error) {
//     console.error("Error fetching saved blogs:", error);
//   }
// };

// const fetchSavedBlogs = async () => {
//   try {
//     const user = auth.currentUser;
//     const firebaseUserId = user ? user.uid : null;

//     if (firebaseUserId) {
//       const firebaseUserId = localStorage.getItem("firebase_user_id");
//       const idToken = await user.getIdToken(true);
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/blog/dashboard/`,
//         {
//           headers: {
//             Authorization: `JWT ${idToken}`,
//           },
//         }
//       );

//       setSavedBlogs(response.data.saved_blogs);

//       const { saved_blogs } = response.data;
//       setSavedBlogs(saved_blogs);
//       // console.log(firebaseUserId) SFRfjZrmEffKOeN1rppviNCzytr1
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };

// ------------------------ This here start

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import styled from "styled-components";
// import { signOut } from "firebase/auth";
// import { auth } from "./FirebaseUtils";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

// const Dashboard = ({ email }) => {
//   const navigate = useNavigate();

//   const handleSignOut = async () => {
//     await signOut(auth);
//     localStorage.removeItem("user");
//     toast.success("Signed out.", { position: "top-center" });
//     navigate("/");
//   };
//   const [savedBlogs, setSavedBlogs] = useState([]);

//   useEffect(() => {
//     fetchSavedBlogs();
//   }, []);

//   const fetchSavedBlogs = async () => {
//     try {
//       const user = auth.currentUser;
//       const firebaseUserId = user ? user.uid : null;

//       if (firebaseUserId) {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}/blog/dashboard/`,
//           {
//             headers: {
//               // Authorization: `Bearer ${firebaseUserId}`,
//               Authorization: firebaseUserId,
//             },
//           }
//         );

//         const { saved_blogs } = response.data;
//         setSavedBlogs(saved_blogs);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <div>
//       <h1>Dashboard</h1>
//       {savedBlogs.length > 0 ? (
//         <ul>
//           {savedBlogs.map((blogTitle, index) => (
//             <li key={index}>{blogTitle}</li>
//           ))}
//         </ul>
//       ) : (
//         <p>No saved blogs found.</p>
//       )}
//       <div>Welcome {email}!</div>
//       <button onClick={handleSignOut}>Sign Out</button>
//     </div>
//   );
// };

// export default Dashboard;

// ------------------------ This here end

import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import {
  AiOutlineStar,
  AiOutlineHeart,
  AiOutlineUser,
  AiOutlineMail,
} from "react-icons/ai";
import { FiMenu, FiX } from "react-icons/fi";
import {
  RiBarChartLine,
  RiSettings4Line,
  RiDeleteBinLine,
  RiLockPasswordLine,
} from "react-icons/ri";
import { auth } from "./FirebaseUtils";
import axios from "axios";
import {
  signOut,
  updateEmail,
  updatePassword,
  sendEmailVerification,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
// import { toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";

const Wrapper = styled.div`
  background-color: rgba(72, 112, 130, 0.6);
  border-radius: 20px;
  display: flex;
  height: 100vh;
  align-items: center;
  // -- to position the DashboardContainer on top of the renderContent but outside the Content component --
  position: relative;
  // -- to position the DashboardContainer on top of the renderContent but outside the Content component --
`;

const Container = styled.div`
  display: flex;
  height: 90vh;
  width: 100vw;
  margin: 20px;
  background: linear-gradient(
    77deg,
    rgba(21, 43, 51, 1) 14%,
    rgba(75, 101, 112, 1) 42%,
    rgba(49, 113, 133, 1) 100%
  );
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  // display: flex;
  // max-width: 1600px;
  // margin: 0 auto;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  // icons are placed in the middle
  align-items: ${({ isOpen }) => (isOpen ? "flex-start" : "center")};
  justify-content: space-between;
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const Content = styled.div`
  flex: 1;
  // margin: 10px 10px 10px 10px;
  // margin: 60px 10px 10px 10px;
  transition: margin-left 0.3s ease-in-out;
  background-color: white;
  // border-radius: 20px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow-y: auto; /* Adds vertical scroll if content overflows */
`;

const NavItem = styled.div`
  display: flex;
  // flex-direction: row;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: rgb(12, 13, 17);
    width: 90%;
    border-radius: 30px;
    transition: 0.3s ease;
  }
`;

const NavItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.div`
  margin-right: ${({ isOpen }) => (isOpen ? "15px" : "0")};
  background-color: rgba(86, 91, 118, 0.2);
  padding: 5px;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
`;

const Section = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: column;
  // -- optional: to center contents of Favourites and Profile --
  // align-items: center;
`;

const SectionTitle = styled.h2`
  margin-top: 0;
`;
const FormSection = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Form = styled.form`
  border: 1px solid;
  border-radius: 30px;
  padding: 20px;
  max-width: 360px;
  margin: 0 auto;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const Button = styled.button`
  background-color: #5999ad;
  background: linear-gradient(160deg, #5999ad 0%, #306d7c 100%);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 35px;
  font-size: 12px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  }

  &:active {
    transform: translateY(0px);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  }
`;

const SettingsSection = styled(Section)`
  & ${SectionTitle} {
    margin-bottom: 10px;
  }

  & ${Form} {
    margin-bottom: 10px;
  }
`;

const SettingsContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // @media (max-width: 768px) {
  //   flex-direction: column;
  // }
`;

const Message = styled.p`
  margin-top: 20px;
`;

const BlogList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const BlogItem = styled.li`
  margin-bottom: 10px;
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // -- to position the DashboardContainer on top of the renderContent but outside the Content component --
  position: absolute;
  top: 0;
  right: 0; /* Position the container on the far right */
  margin: 10px 10px 0 0;
  // -- to position the DashboardContainer on top of the renderContent but outside the Content component --
`;

const WelcomeMessage = styled.div`
  // margin-top: 20px;
`;

const SignOutButton = styled.button`
  // margin-top: 20px;
`;

const linkStyle = {
  textDecoration: "none",
  color: "#5999ad",
};

// Delete Button Styling Start

const openAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const closeAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const AnimatedTrashCan = styled(RiDeleteBinLine)`
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`;

const TrashCanIcon = styled(AnimatedTrashCan)`
  &:hover {
    animation-name: ${openAnimation};
  }

  &:active {
    animation-name: ${closeAnimation};
  }
`;

// const DeleteButton = styled(Button)`
const DeleteButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  height: auto;
  margin: 0 auto;

  &:hover {
    background-color: #d32f2f;
  }
`;

const ConfirmToastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  height: 100%;

  & > * {
    margin-bottom: 16px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  button + button {
    margin-left: 1rem;
  }
`;

// Delete Button Styling End

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  width: 50px;
  // follow SidebarContainer
`;

const SidebarContainer = styled.div`
  align-items: flex-start;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #293241;
  color: #fff;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  opacity: ${({ isOpen }) => (isOpen ? 0.9 : 1)};
  width: ${({ isOpen }) => (isOpen ? "auto" : "50px")};
`;

const SidebarContent = styled.div``;

const SubItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubNavItem = styled.div`
  display: flex;
  flex-direction: row;
`;

const Dashboard = ({ email }) => {
  const [activeNavItem, setActiveNavItem] = useState("Favourites");
  const [activeSettingsNavItem, setActiveSettingsNavItem] = useState(false);
  const [savedBlogs, setSavedBlogs] = useState([]);
  // useState(email) to show old email
  const [newEmail, setNewEmail] = useState(email);
  const [emailVerified, setEmailVerified] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(true);
  const [showNavigation, setShowNavigation] = useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleToggleNavigation = () => {
    setShowNavigation(!showNavigation);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    setEmailVerified(user.emailVerified);
  }, []);

  useEffect(() => {
    fetchSavedBlogs();
  }, []);

  const handleNavItemClick = (navItem) => {
    setActiveNavItem(navItem);
  };

  const handleSettingsNavItemClick = () => {
    setActiveSettingsNavItem(!activeSettingsNavItem);
  };

  const fetchSavedBlogs = async () => {
    try {
      const user = auth.currentUser;
      const firebaseUserId = user ? user.uid : null;

      if (firebaseUserId) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/blog/dashboard/`,
          {
            headers: {
              Authorization: firebaseUserId,
            },
          }
        );

        const { saved_blogs } = response.data;
        setSavedBlogs(saved_blogs);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const ChangeEmail = () => {
    return (
      <Form onSubmit={handleEmailChange}>
        <SectionTitle>Change Email</SectionTitle>
        <FormGroup>
          <Label>New Email</Label>
          <Input
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder="New Email"
          />
        </FormGroup>
        <FormGroup>
          <Label>Current Password</Label>
          <Input
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            placeholder="Current Password"
          />
          <div>
            {!emailVerified ? (
              <button onClick={handleResendVerification}>
                Send Verification Link Again
              </button>
            ) : null}
          </div>
          {/* If not verified, resend verification link will appear */}
        </FormGroup>
        <Button type="submit">Change Email</Button>
      </Form>
    );
  };

  const ChangePassword = () => {
    return (
      <Form onSubmit={handlePasswordChange}>
        <SectionTitle>Change Password</SectionTitle>
        <FormGroup>
          <Label>New Password</Label>
          <Input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="New Password"
          />
        </FormGroup>
        <FormGroup>
          <Label>Current Password</Label>
          <Input
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            placeholder="Current Password"
          />
        </FormGroup>
        <Button type="submit">Change Password</Button>
      </Form>
    );
  };

  const DeleteAccount = () => {
    return (
      <DeleteButton onClick={handleDeleteAccount}>
        <TrashCanIcon size={16} />
        Delete Account
      </DeleteButton>
    );
  };

  const renderContent = () => {
    switch (activeNavItem) {
      case "Favourites":
        return (
          <Section>
            <SectionTitle>Your Favorites</SectionTitle>
            {savedBlogs.length > 0 ? (
              <BlogList>
                {savedBlogs.map((blog, index) => (
                  <Link to={`/blog/${blog.id}`} style={linkStyle}>
                    {blog.title}
                  </Link>
                ))}
              </BlogList>
            ) : (
              <Message>No saved blogs found.</Message>
            )}
          </Section>
        );
      case "Settings":
        return (
          <SettingsSection>
            <SettingsContainer>
              <FormSection>
                <ChangeEmail />
                <ChangePassword />
              </FormSection>
              <DeleteAccount />
            </SettingsContainer>
          </SettingsSection>
        );
      case "Profile":
        return <Section>Email: {email}</Section>;
      case "ChangeEmail":
        return (
          <Section>
            <ChangeEmail />
          </Section>
        );
      case "ChangePassword":
        return (
          <Section>
            <ChangePassword />
          </Section>
        );
      case "DeleteAccount":
        return (
          <Section>
            <DeleteAccount />
          </Section>
        );
      default:
        return null;
    }
  };

  const handleSignOut = async () => {
    await signOut(auth);
    localStorage.removeItem("user");
    toast.success("Signed out.", { position: "top-center" });
    navigate("/");
  };

  const handleEmailChange = async (e) => {
    e.preventDefault();
    try {
      const user = auth.currentUser;

      // Re-authenticate the user
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      await reauthenticateWithCredential(user, credential);

      // Update the email
      await updateEmail(user, newEmail);

      // Send verification email to the new email address
      await sendEmailVerification(user);

      let idToken = await user.getIdToken(true);

      // Update email in Django backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/change-email`,
        {
          new_email: newEmail,
          password: currentPassword,
        },
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${idToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Email updated successfully.", {
          position: "top-center",
        });
        setNewEmail("");
        setCurrentPassword("");
      } else {
        toast.error("Failed to update email. Please try again later.", {
          position: "top-center",
        });
      }
    } catch (error) {
      toast.error(error.message, { position: "top-center" });
    }
  };

  const handleResendVerification = async () => {
    try {
      const user = auth.currentUser;

      // Send email verification link again
      await sendEmailVerification(user);

      toast.success("Verification link sent.", { position: "top-center" });
    } catch (error) {
      toast.error(error.message, { position: "top-center" });
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );

      // Reauthenticate user with current password
      await reauthenticateWithCredential(user, credential);

      // Update the password
      await updatePassword(user, newPassword);

      // Update password in Django backend

      let idToken = await user.getIdToken(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/change-password`,
        {
          password: newPassword,
          currentPassword,
        },
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${idToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Password updated successfully.", {
          position: "top-center",
        });
        setCurrentPassword("");
        setNewPassword("");
      } else {
        toast.error("Failed to update password. Please try again later.", {
          position: "top-center",
        });
      }
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        toast.error("The current password is incorrect.", {
          position: "top-center",
        });
      } else if (error.code === "auth/requires-recent-login") {
        toast.error(
          "This action requires recent login. Please sign in again.",
          { position: "top-center" }
        );
      } else {
        toast.error("Failed to update password. Please try again later.", {
          position: "top-center",
        });
      }

      console.error(error);
    }
  };

  // To show confirmation prompt before account deletion
  const showDialog = () => {
    return new Promise((resolve) => {
      setConfirmOpen(true);
      const handleConfirm = () => {
        setConfirmOpen(false);
        resolve(true);
        toast.dismiss();
      };
      const handleCancel = () => {
        setConfirmOpen(false);
        resolve(false);
        toast.dismiss();
      };

      toast.dismiss(); // Dismiss any existing toasts

      toast.info(
        <ConfirmToastWrapper>
          <p>
            Are you sure you want to delete your account? This action cannot be
            undone.
          </p>
          <ButtonGroup>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleConfirm}>Delete</Button>
          </ButtonGroup>
        </ConfirmToastWrapper>,
        {
          position: "top-center",
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          hideProgressBar: true,
        }
      );
    });
  };

  const handleDeleteAccount = async () => {
    try {
      const confirmed = await showDialog();

      if (!confirmed) {
        return;
      }

      const user = auth.currentUser;
      // let idToken = await getIdTokenWithFreshLogin(user); //not really working
      let idToken = await user.getIdToken(true);

      // Delete account from Firebase
      user
        .delete()
        .then(() => {
          // Account deleted successfully from Firebase
          // Now, send a request to delete the account from Django backend
          axios
            .delete(
              `${process.env.REACT_APP_API_URL}/api/auth/delete-account`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${idToken}`,
                },
              }
            )
            .then(() => {
              // Account deleted successfully from Django
              toast.success("Account deleted successfully.", {
                position: "top-center",
              });
            })
            .catch(() => {
              // Failed to delete account from Django
              toast.error("Failed to delete account. Please try again later.", {
                position: "top-center",
              });
            });
        })
        .catch(() => {
          // Failed to delete account from Firebase
          toast.error("Failed to delete account. Please try again later.", {
            position: "top-center",
          });
        });
    } catch (error) {
      if (
        error.code === "auth/requires-recent-login" ||
        error.code === "auth/credential-too-old-login-again"
      ) {
        // Prompt the user to log in again
        toast.error(
          "Please log in again to proceed with the account deletion.",
          {
            position: "top-center",
          }
        );
        // Redirect the user to the login page
      } else {
        // Handle other errors
        toast.error("Failed to delete account. Please try again later.", {
          position: "top-center",
        });
      }
    }
  };
  // const showPasswordDialog = () => {
  //   return new Promise((resolve, reject) => {
  //     toast.info(<PasswordDialog onConfirm={resolve} onCancel={reject} />, {
  //       position: "top-center",
  //       autoClose: false,
  //       closeOnClick: false,
  //       draggable: false,
  //       hideProgressBar: true,
  //       className: "confirm-toast", // Add a custom class for styling
  //     });
  //   });
  // };

  // const PasswordDialog = ({ onConfirm, onCancel }) => {
  //   const [password, setPassword] = useState("");

  //   const handleConfirm = () => {
  //     if (password.trim() !== "") {
  //       onConfirm(password);
  //       setPassword(password);
  //       console.log("trimmed", password);
  //     } else {
  //       // Display an error toast or take appropriate action
  //       toast.error("Please enter your password.", {
  //         position: "top-center",
  //       });
  //     }
  //   };

  //   const handleCancel = () => {
  //     onCancel();
  //   };

  //   return (
  //     <ConfirmToastWrapper>
  //       <p>Please enter your password to confirm account deletion:</p>
  //       <Input
  //         type="password"
  //         placeholder="Current Password"
  //         value={password}
  //         onChange={(e) => setPassword(e.target.value)}
  //       />
  //       <ButtonGroup>
  //         <Button onClick={handleCancel}>Cancel</Button>
  //         <Button onClick={handleConfirm}>Confirm</Button>
  //       </ButtonGroup>
  //     </ConfirmToastWrapper>
  //   );
  // };

  // const getIdTokenWithFreshLogin = async (user, password) => {
  //   return new Promise(async (resolve, reject) => {
  //     const confirmed = await showPasswordDialog();
  //     console.log("frsh", password);

  //     if (!confirmed) {
  //       reject(); // Reject the promise if the user cancels
  //       return;
  //     }

  //     // const { password } = confirmed; // Get the password from the confirmation object
  //     const email = user.email;
  //     const credential = EmailAuthProvider.credential(email, password);

  //     try {
  //       await reauthenticateWithCredential(credential);
  //       const idToken = await user.getIdToken(true);
  //       resolve(idToken); // Resolve the promise with the ID token
  //     } catch (error) {
  //       reject(error); // Reject the promise with the error
  //       console.log(error);
  //       console.log(email);
  //       console.log(password);
  //       console.log(credential);
  //     }
  //   });
  // };

  return (
    <Wrapper>
      <Container>
        {/* <DashboardNavbar></DashboardNavbar> */}

        <SidebarContainer isOpen={isOpen}>
          <ToggleButton onClick={handleToggle}>
            {isOpen ? <FiX /> : <FiMenu />}
          </ToggleButton>
          {/* <SidebarContent>
            {showNavigation && (
              <Navigation isOpen={isOpen}>
                <NavItem onClick={() => handleNavItemClick("Favourites")}>
                  <IconWrapper isOpen={isOpen}>
                    <AiOutlineHeart />
                  </IconWrapper>
                  {isOpen && "Favourites"}
                </NavItem>
                <NavItem onClick={() => handleNavItemClick("Settings")}>
                  <IconWrapper isOpen={isOpen}>
                    <RiSettings4Line />
                  </IconWrapper>
                  {isOpen && "Settings"}
                </NavItem>
                <NavItem onClick={() => handleNavItemClick("Profile")}>
                  <IconWrapper isOpen={isOpen}>
                    <AiOutlineUser />
                  </IconWrapper>
                  {isOpen && "Profile"}
                </NavItem>
              </Navigation>
            )}
          </SidebarContent> */}
          <SidebarContent>
            {showNavigation && (
              <Navigation isOpen={isOpen}>
                <NavItem
                  onMouseEnter={() => {
                    handleNavItemClick("Favourites");
                  }}
                >
                  <NavItemWrapper>
                    <IconWrapper isOpen={isOpen}>
                      <AiOutlineHeart />
                    </IconWrapper>
                    {isOpen && "Favourites"}
                  </NavItemWrapper>
                </NavItem>
                {/* Settings open on hover */}
                <NavItem
                  onMouseEnter={() => {
                    handleNavItemClick("Settings");
                    handleSettingsNavItemClick(true);
                  }}
                  onMouseLeave={() => {
                    handleSettingsNavItemClick(false);
                  }}
                >
                  <NavItemWrapper>
                    <IconWrapper isOpen={isOpen}>
                      <RiSettings4Line />
                    </IconWrapper>

                    {isOpen && "Settings"}
                  </NavItemWrapper>
                  {activeSettingsNavItem && (
                    <SubItemsWrapper>
                      <SubNavItem
                        onClick={() => handleNavItemClick("ChangeEmail")}
                      >
                        <IconWrapper isOpen={isOpen}>
                          <AiOutlineMail />
                        </IconWrapper>
                        {isOpen && "Change Email"}
                      </SubNavItem>
                      <SubNavItem
                        onClick={() => handleNavItemClick("ChangePassword")}
                      >
                        <IconWrapper isOpen={isOpen}>
                          <RiLockPasswordLine />
                        </IconWrapper>
                        {isOpen && "Change Password"}
                      </SubNavItem>
                      <SubNavItem
                        onClick={() => handleNavItemClick("DeleteAccount")}
                      >
                        <IconWrapper isOpen={isOpen}>
                          <RiDeleteBinLine />
                        </IconWrapper>
                        {isOpen && "Delete Account"}
                      </SubNavItem>
                    </SubItemsWrapper>
                  )}
                </NavItem>
                <NavItem
                  onMouseEnter={() => {
                    handleNavItemClick("Profile");
                  }}
                >
                  <NavItemWrapper>
                    <IconWrapper isOpen={isOpen}>
                      <AiOutlineUser />
                    </IconWrapper>
                    {isOpen && "Profile"}
                  </NavItemWrapper>
                </NavItem>
              </Navigation>
            )}
          </SidebarContent>
        </SidebarContainer>
        <Content isOpen={isOpen}>{renderContent()}</Content>
        {/* <DashboardContainer>
        <WelcomeMessage>Welcome {email}!</WelcomeMessage>
        <SignOutButton onClick={handleSignOut}>Sign Out</SignOutButton>
      </DashboardContainer> */}
      </Container>
    </Wrapper>
  );
};

export default Dashboard;

// ---------------------

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import styled from "styled-components";
// import { signOut } from "firebase/auth";
// import { auth } from "./FirebaseUtils";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

// const DashboardContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 40px;
//   background: linear-gradient(160deg, #5999ad 0%, #306d7c 100%);
//   border-radius: 20px;
//   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
//   backdrop-filter: blur(10px);
//   max-width: 600px;
//   margin: 0 auto;
// `;

// const Title = styled.h1`
//   color: #fff;
//   font-size: 24px;
//   margin-bottom: 20px;
// `;

// const BlogList = styled.ul`
//   list-style: none;
//   padding: 0;
//   margin: 20px 0;
// `;

// const BlogItem = styled.li`
//   color: #fff;
//   font-size: 16px;
//   margin-bottom: 10px;
// `;

// const Message = styled.p`
//   color: #fff;
//   font-size: 16px;
// `;

// const WelcomeMessage = styled.div`
//   color: #fff;
//   font-size: 18px;
//   margin-bottom: 20px;
// `;

// const SignOutButton = styled.button`
//   background-color: #32495c;
//   color: #fff;
//   border: none;
//   border-radius: 8px;
//   padding: 12px 20px;
//   font-size: 16px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #274c5e;
//   }
// `;

// const Dashboard = ({ email }) => {
//   const navigate = useNavigate();

//   const handleSignOut = async () => {
//     await signOut(auth);
//     localStorage.removeItem("user");
//     toast.success("Signed out.", { position: "top-center" });
//     navigate("/");
//   };
//   const [savedBlogs, setSavedBlogs] = useState([]);

//   useEffect(() => {
//     fetchSavedBlogs();
//   }, []);

//   const fetchSavedBlogs = async () => {
//     try {
//       const user = auth.currentUser;
//       const firebaseUserId = user ? user.uid : null;

//       if (firebaseUserId) {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}/blog/dashboard/`,
//           {
//             headers: {
//               // Authorization: `Bearer ${firebaseUserId}`,
//               Authorization: firebaseUserId,
//             },
//           }
//         );

//         const { saved_blogs } = response.data;
//         setSavedBlogs(saved_blogs);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <DashboardContainer>
//       <Title>Dashboard</Title>
//       {savedBlogs.length > 0 ? (
//         <BlogList>
//           {savedBlogs.map((blogTitle, index) => (
//             <BlogItem key={index}>{blogTitle}</BlogItem>
//           ))}
//         </BlogList>
//       ) : (
//         <Message>No saved blogs found.</Message>
//       )}
//       <WelcomeMessage>Welcome {email}!</WelcomeMessage>
//       <SignOutButton onClick={handleSignOut}>Sign Out</SignOutButton>
//     </DashboardContainer>
//   );
// };

// export default Dashboard;
