import React, { useState, useEffect, useRef } from "react";
import { auth } from "./FirebaseUtils";
import SignInButton from "./LoginWithEmailPassword";
import GoogleButton from "./SignInWithGoogle";
import SignUp from "./SignUpWithEmailPassword";
// import styled from "styled-components";
import styled, { keyframes } from "styled-components";
import { HiOutlineMail, HiOutlineLockClosed } from "react-icons/hi";
import { FaCheck } from "react-icons/fa";

import Dashboard from "./Dashboard";
import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
  sendEmailVerification,
} from "firebase/auth";

import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import { toast } from "react-toastify";
import { RiMailLine } from "react-icons/ri"; //To point to email input box for missing email

const WrapperEntranceAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-50px) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  max-width: 400px;
  margin: 0 auto;
  min-height: 60vh;
  max-height: 100vh;
  font-family: Arial, sans-serif;
  background: linear-gradient(to bottom right, #1d2b45, #5999ad);
  padding: 40px;
  border-radius: 24px;
  box-shadow: 0px 0px 50px 5px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
  // animation: rotateWrapper 6s ease-in-out infinite;
  animation: ${WrapperEntranceAnimation} 0.5s ease-out;

  @keyframes rotateWrapper {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(1deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  font-weight: medium;
  margin-bottom: 10px;
  border-radius: 12px;
  background-color: #f6f6f6;
  color: #3c3c3c;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #d9d9d9;
    cursor: pointer;
  }

  &:active {
    background-color: #bfbfbf;
    transform: scale(0.98);
  }
`;

const SeparatorWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;

const SeparatorHorizontalLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
`;

const SeparatorText = styled.div`
  margin: 0 10px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  font-size: 14px;
`;

// const InputContainer = styled.div`
//   display: flex;
//   align-items: center;
//   border: 1px solid gray;
//   border-radius: 4px;
//   padding: 8px;
// `;

// const Input = styled.input`
//   border: none;
//   outline: none;
//   flex: 1;
//   margin-left: 8px;
// `;

const EmailIcon = styled(HiOutlineMail)`
  font-size: 20px;
  color: white;
  margin: 0 10px;
`;

const PasswordIcon = styled(HiOutlineLockClosed)`
  font-size: 20px;
  color: white;
  margin: 0 10px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: none;
  margin-bottom: 10px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  color: white;
  font-weight: bold;
  font-size: 16px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-weight: normal;
  }
`;

const SeparatorVerticalLine = styled.div`
  height: 20px;
  border-left: 2px solid gray;
  margin: 0 0px;
`;

const PasswordToggle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  transition: all 0.2s ease;
  margin: 0 10px;

  &:hover {
    color: white;
    border-color: rgba(255, 255, 255, 0.8);
    transform: translateY(-2px);
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
`;

const CheckboxInput = styled.input`
  position: relative;
  width: 20px;
  height: 20px;
  appearance: none;
  outline: none;
  cursor: pointer;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: "transparent";
    box-shadow: inset 0px 0px 0px 1px #7f7f7f;
    transition: box-shadow 0.2s ease-in-out;
  }
  &:checked:before {
    box-shadow: inset 0px 0px 0px 3px rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.5);
  }
  &:checked:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    animation: checkmark 0.3s ease-in-out forwards;
  }

  @keyframes checkmark {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }
    50% {
      transform: translate(-50%, -50%) scale(1.2);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
`;

const CheckboxLabel = styled.label`
  font-size: 12px;
  margin-left: 5px;
  color: white;
`;
const CheckboxTick = styled(FaCheck)`
  font-size: 14px;
  color: #007aff;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.7;
  }
`;

// Create a styled component for the forgot password link
const ForgotPasswordLink = styled.a`
  font-size: 10px;
  text-decoration: none;
  margin-left: auto;
  padding-bottom: 10px;
  color: #fff;
  transition: all 0.3s ease-in-out;

  // Add styling for when the forgot password link is hovered over
  &:hover {
    font-size: 12px;
    cursor: pointer;
  }
`;

const ToggleSigninSignupWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  width: 60%;
  padding: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
  margin-bottom: 20px;
`;

const ToggleButton = styled.button`
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.active ? "#fff" : "#333")};
  background: ${(props) =>
    props.active
      ? "linear-gradient(160deg, #5999ad 0%, #306d7c 100%)"
      : "transparent"};
  border: none;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 5px 30px;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
  }

  &:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

// const Wrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   max-width: 400px;
//   margin: 0 auto;
//   max-height: 100vh;
//   font-family: Arial, sans-serif;
//   background-color: #f5f5f5;
//   padding: 40px;
//   border-radius: 12px;
//   box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
// `;

// const Button = styled.button`
//   width: 100%;
//   height: 40px;
//   font-weight: medium;
//   margin-bottom: 10px;
//   border-radius: 12px;
//   background-color: #3c3c3c;
//   color: white;
//   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 8px;
//   &:hover {
//     background-color: #2768a5;
//     cursor: pointer;
//   }
//   &:active {
//     background-color: #1c1c1c;
//   }
// `;

// const SeparatorWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   width: 100%;
//   margin: 20px 0;
// `;

// const SeparatorLine = styled.div`
//   flex-grow: 1;
//   height: 1px;
//   background-color: #ccc;
// `;

// const SeparatorText = styled.div`
//   margin: 0 10px;
//   color: #ccc;
//   font-weight: bold;
// `;

// const InputContainer = styled.div`
//   display: flex;
//   align-items: center;
//   border-radius: 10px;
//   border: 1px solid #dbdfea;
//   margin-bottom: 10px;
//   width: 100%;

//   &:focus-within {
//     border-color: #1475cf;
//   }
// `;

// const Input = styled.input`
//   flex: 1;
//   padding: 10px;
//   border-radius: 10px;
//   border: 1px solid #dbdfea;

//   &::-webkit-outer-spin-button,
//   &::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }

//   &:focus {
//     outline: none;
//   }
// `;

// const PasswordToggle = styled.button`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 5px;
//   border: none;
//   background-color: transparent;
//   cursor: pointer;
//   color: #8e8e93;

//   &:hover {
//     color: #1475cf;
//   }
// `;

// const CheckboxContainer = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 20px;
//   width: 100%;
// `;

// const CheckboxInput = styled.input`
//   margin-right: 5px;
// `;

// const CheckboxLabel = styled.label`
//   font-size: 12px;
//   color: black; //Keep me signed in font colour for night mode consistency
// `;

// // Create a styled component for the forgot password link
// const ForgotPasswordLink = styled.a`
//   font-size: 10px;
//   text-decoration: none;
//   margin-left: 10px;
//   margin-right: auto;
//   padding-bottom: 20px;
//   color: #1475cf;

//   // Add styling for when the forgot password link is hovered over
//   &:hover {
//     cursor: pointer;
//   }
// `;

// const ToggleSigninSignupWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #333;
//   border-radius: 12px;
//   width: 60%;
//   padding: 10px;
//   // height: 30px;
//   margin-bottom: 10px;
//   overflow: hidden;
// `;

// const ToggleButton = styled.button`
//   justify-content: center;
//   align-items: center;
//   color: ${(props) => (props.active ? "#333" : "#5999ad")};
//   background: ${(props) => (props.active ? "#5999ad" : "#333")};
//   background-image: ${(props) =>
//     props.active
//       ? "linear-gradient(160deg, #5999ad 0%, #80D0C7 100%)"
//       : "#333"};
//   border: none;
//   font-size: 16px;
//   font-weight: 500;
//   transition: all 0.3s ease;
//   padding: 5px 30px;

//   &:hover {
//     cursor: pointer;
//     color: #fff;
//   }

//   &:first-child {
//     border-top-left-radius: 12px;
//     border-bottom-left-radius: 12px;
//   }

//   &:last-child {
//     border-top-right-radius: 12px;
//     border-bottom-right-radius: 12px;
//   }
// `;

const AuthorizationForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showEmailIcon, setShowEmailIcon] = useState(false);

  const togglePasswordVisibility = () => setIsVisible(!isVisible);
  const [toggleSignIn, setToggleSignIn] = useState("sign-in");

  const emailInputRef = useRef(null);

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      window.alert("Password reset link has been sent to your email.");
      toast.success(`Password reset email sent to ${email}`, {
        position: "top-center",
      });
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
          toast.error("Please provide a valid email address.", {
            position: "top-center",
          });
          break;

        case "auth/user-not-found":
          toast.error(`User with email ${email} not found`, {
            position: "top-center",
          });
          break;

        case "auth/missing-email":
          toast.info("Please provide an email in the email input box", {
            position: "top-center",
          });
          emailInputRef.current.focus();
          setShowEmailIcon(true);
          break;

        case "auth/network-request-failed":
          toast.error("Something went wrong, check your network connection", {
            position: "top-center",
          });
          break;

        case "auth/too-many-requests":
          toast.error(
            "You have attempted to reset your password too many times in a short period.",
            {
              position: "top-center",
            }
          );
          break;
        default:
          console.log(error);
          toast.error("Failed to send reset password email.", {
            position: "top-center",
          });
          break;
      }
    }
  };

  const callPrivateApi = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/auth/authenticated`,
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log(data);
    window.alert(`message: ${data.message} user: ${data.user}`);
  };

  const refreshToken = async () => {
    const user = await auth.currentUser;
    if (user) {
      let idToken = await user.getIdToken(true);
      setToken(idToken);
    }
  };

  // Refer to LoginWithEmailPassword.js
  const handleSignIn = async () => {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      // console.log(res.user);
      const user = res.user;

      if (!user.emailVerified) {
        await sendEmailVerification(user);
        // await signOut(auth); // Sign out the user if email is not verified
        toast.info("Please verify your email before signing in.", {
          position: "top-center",
        });
        return;
      }

      let idToken = await user.getIdToken(true);
      setToken(idToken);
      setLoggedIn(true);

      // Store the user credentials in localStorage
      localStorage.setItem(
        "user",
        JSON.stringify({ email, password, idToken })
      );
      toast.success("Signed in.", { position: "top-center" });
    } catch (e) {
      toast.error(e.code);
    }
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedToken = localStorage.getItem("token");
    const storedLoggedIn = localStorage.getItem("loggedIn"); // Retrieve the 'loggedIn' state from localStorage
    if (storedEmail && storedToken && storedLoggedIn) {
      setEmail(storedEmail);
      setToken(storedToken);
      setLoggedIn(storedLoggedIn === "true"); // Convert the string to a boolean
    }
  }, []);

  useEffect(() => {
    if (rememberMe && token) {
      localStorage.setItem("email", email);
      localStorage.setItem("token", token);
      localStorage.setItem("loggedIn", true); // Set the 'loggedIn' key in localStorage to true
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("token");
      localStorage.removeItem("loggedIn"); // Remove the 'loggedIn' key from localStorage
    }
  }, [rememberMe, email, token]);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));

    if (
      storedUser &&
      storedUser.email &&
      storedUser.password &&
      storedUser.idToken
    ) {
      setEmail(storedUser.email);
      setPassword(storedUser.password);
      setToken(storedUser.idToken);
      setLoggedIn(true);
    }
  }, []);

  const handleButtonClick = (button) => {
    setToggleSignIn(button);
  };

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* So ToggleSigninSignupWrapper is in the middle, but CheckboxContainer and ForgotPasswordLink are not affected, ie. at opposite ends */}
        <ToggleSigninSignupWrapper>
          <ToggleButton
            active={toggleSignIn === "sign-in"}
            onClick={() => handleButtonClick("sign-in")}
          >
            Sign In
          </ToggleButton>
          <ToggleButton
            active={toggleSignIn === "sign-up"}
            onClick={() => handleButtonClick("sign-up")}
          >
            Sign Up
          </ToggleButton>
        </ToggleSigninSignupWrapper>
      </div>

      {toggleSignIn === "sign-in" ? (
        <>
          <InputContainer>
            <EmailIcon />
            <SeparatorVerticalLine />
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              ref={emailInputRef}
            />
            {showEmailIcon && (
              <PasswordToggle>
                {/* Similar to PasswordToggle styling */}
                <RiMailLine size={15} color="red" />
              </PasswordToggle>
            )}
          </InputContainer>
          <InputContainer>
            <PasswordIcon />
            <SeparatorVerticalLine />
            <Input
              type={isVisible ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <PasswordToggle onClick={togglePasswordVisibility}>
              {isVisible ? <MdVisibilityOff /> : <MdVisibility />}
            </PasswordToggle>
          </InputContainer>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <CheckboxContainer>
                <CheckboxInput
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                {rememberMe && <CheckboxTick />}
                <CheckboxLabel>Keep me signed in</CheckboxLabel>
              </CheckboxContainer>
            </div>
            <div>
              <ForgotPasswordLink
                onClick={handleForgotPassword}
                style={{ marginLeft: "10px" }}
              >
                Forgot password?
              </ForgotPasswordLink>
            </div>
          </div>

          <SignInButton
            email={email}
            password={password}
            callback={handleSignIn}
          />
          <SeparatorWrapper>
            <SeparatorHorizontalLine />
            <SeparatorText>or</SeparatorText>
            <SeparatorHorizontalLine />
          </SeparatorWrapper>
          <GoogleButton>Sign in with Google</GoogleButton>
        </>
      ) : (
        <>
          <InputContainer>
            <EmailIcon />
            <SeparatorVerticalLine />
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              ref={emailInputRef}
            />
            {showEmailIcon && (
              <PasswordToggle>
                {/* Similar to PasswordToggle styling */}
                <RiMailLine size={15} color="red" />
              </PasswordToggle>
            )}
          </InputContainer>
          <InputContainer>
            <PasswordIcon />
            <SeparatorVerticalLine />
            <Input
              type={isVisible ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <PasswordToggle onClick={togglePasswordVisibility}>
              {isVisible ? <MdVisibilityOff /> : <MdVisibility />}
            </PasswordToggle>
          </InputContainer>
          <SignUp
            email={email}
            password={password}
            callback={setToken}
            emailInputRef={emailInputRef}
            setShowEmailIcon={setShowEmailIcon}
          />
        </>
      )}
    </Wrapper>
  );
};

export default AuthorizationForm;
