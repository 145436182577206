// import React, { ReactNode } from 'react';
import React, { useState, useEffect } from "react";
// import classNames from 'classnames';
import { Icon } from "@iconify/react";

// import GridLayout from "react-grid-layout";
// import BlogList from "./BlogList";
import styled from "styled-components";
// import { Link, Navigate, createSearchParams, useNavigate, useParams } from "react-router-dom";
import { devices } from "./Device";

// https://github.com/angelo24782/reactjs-list-grid-view

// const GridListButton = styled.div`
//   // background-color: yellow;
//   max-width: 1225px;
//   margin: 15px;
//   display: flex;
//   justify-content: flex-end;

//   @media (max-width: 1080px) {
//     justify-content: center;
//   }
// `;

const GridListButton = styled.div`
  margin: 10px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1080px) {
    justify-content: center;
  }
`;

const GridButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.5rem;
  color: ${(props) => (props.active ? "#007bff" : "#5999ad")};

  &:hover {
    color: #007bff;
  }
`;

const Separator = styled.div`
  width: 2px;
  height: 24px;
  margin: 0 10px;
  background-color: #6c757d;
`;

export default function GridList({ isGrid, setIsGrid }) {
  const handleGrid = () => {
    setIsGrid(true);
  };

  const handleList = () => {
    setIsGrid(false);
  };

  return (
    <GridListButton>
      <GridButton active={isGrid} onClick={handleGrid}>
        <Icon icon="healthicons:ui-menu-grid-outline" />
      </GridButton>

      <Separator />

      <GridButton active={!isGrid} onClick={handleList}>
        <Icon icon="bi:list" />
      </GridButton>
    </GridListButton>
  );
}
