import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import axios from "axios";

const SearchContext = createContext();

export const SearchContextProvider = ({ children }) => {
  // const [blog, setBlog] = useState({
  //   title: "",
  //   description: "",
  // });

  const [blog, setBlog] = useState([]);
  const value = useMemo(() => [blog, setBlog], [blog]);

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const retrieveBlog = async () => {
      try {
        // const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v2/pages/?type=blog.Blog&fields=description,thumbnail`)
        const response = await axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/v2/pages/?child_of=7&type=blog.Blog&fields=description,thumbnail&order=-first_published_at`
          )
          // Home: Korean blog
          .then((response) => {
            setBlog(response.data.items);
          });
      } catch (err) {
        console.log(err);
      }
    };

    retrieveBlog();
  }, []);

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export default SearchContext;
