import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DarkModeToggle from "./DarkModeToggle";
// import "./styles.scss";
import StackingTabsLogo from "../assets/images/stacking_tabs_logo_transparent.png";
import { devices } from "./Device";

import { FaMastodon, FaGithub, FaTwitter, FaUserCircle } from "react-icons/fa";

import { auth } from "./authentication/FirebaseUtils";
import { signOut } from "firebase/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";

// const Wrapper = styled.span`
//   @media screen and ${devices.tablet} {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }

//   @media screen and ${devices.laptop} {
//     display: grid;
//     grid-template-rows: 5fr 1fr;
//     grid-template-areas: "titleheader sideheader";
//     justify-self: center;
//     align-items: center;
//   }
// `;

// const TitleHeader = styled.span`
//   grid-area: titleheader;
//   grid-column: 1 / 6;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// const Logo = styled.img`
//   width: 40px;
//   height: 30px;
//   justify-content: center;
//   align-items: center;
// `;

// const Name = styled.div`
//   font-size: calc(15px + 1vw);
// `;

// const UnstyledLink = styled(Link)`
//   &:focus,
//   &:hover,
//   &:visited,
//   &:link,
//   &:active {
//     text-decoration: none;
//   }
// `;

// const UnstyledLinkLightBlue = styled(UnstyledLink)`
//   color: #5999ad;
// `;

// const Title = styled(UnstyledLinkLightBlue)`
//   color: #5999ad;
//   white-space: nowrap;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const Slogan = styled.p`
//   margin-top: -3px;
//   margin-left: 100px;
//   color: #35363a;
//   font-size: calc(10px + 0.390625vw);
// `;

// const NavLinks = () => {
//   return (
//     <Links>
//       <NavLink href="https://github.com">
//         <FaGithub />
//       </NavLink>
//       <NavLink href="https://twitter.com">
//         <FaTwitter />
//       </NavLink>
//     </Links>
//   );
// };

// const Links = styled.div`
//   // display: flex;
//   // align-items: center;
// `;

// const NavLink = styled.a`
//   margin-left: 20px;
//   color: #5999ad;
//   font-size: 24px;
//   cursor: pointer;

//   &:hover {
//     color: #fff;
//   }
// `;

// const SideHeader = styled.span`
//   @media screen and ${devices.mobileS} {
//     display: flex;
//     justify-content: center;
//     align-self: center;
//   }

//   @media screen and ${devices.laptop} {
//     grid-area: sideheader;
//     grid-column: 5;
//     display: flex;
//     justify-content: flex-end;
//     align-self: flex-end;
//   }
// `;

// const Header = (props) => {
//   const { toggleTheme } = props;
//   return (
//     <Wrapper>
//       <TitleHeader>
//         <Title to="">
//           <Logo src={StackingTabsLogo} alt="Stacking Tabs Logo" />
//           <Name>Stacking Tabs</Name>
//         </Title>
//         <Slogan>...playground for my mind...</Slogan>
//       </TitleHeader>
//       <SideHeader>
//       <DarkModeToggle toggleTheme={toggleTheme} />
//         <NavLinks />
//       </SideHeader>
//     </Wrapper>
//   );
// };

const Wrapper = styled.span`
  @media screen and ${devices.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and ${devices.laptop} {
    display: grid;
    grid-template-rows: 5fr 1fr;
    grid-template-areas: "titleheader sideheader";
    justify-self: center;
    align-items: center;
  }
`;

const TitleHeader = styled.span`
  grid-area: titleheader;
  grid-column: 1 / 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: 10px;
  text-decoration: none;
`;

const Logo = styled.img`
  width: 40px;
  height: 30px;
  justify-content: center;
  align-items: center;
`;

const Name = styled.div`
  color: #5999ad;
  font-size: calc(15px + 1vw);
  margin-left: 10px;
  &:hover {
    color: #007bff;
  }
`;

const Slogan = styled.p`
  margin-left: 10px;
  color: #35363a;
  font-size: calc(10px + 0.390625vw);
`;

// const NavLinks = () => {
//   return (
//     <Links>
//       <NavLink href="https://github.com/prolenodev">
//         <FaGithub />
//       </NavLink>
//       <NavLink href="https://twitter.com/stackingtabs">
//         <FaTwitter />
//       </NavLink>
//       <NavLink href="/#/login">
//       <LoginButton>Login</LoginButton>
//       </NavLink>
//     </Links>
//   );
// };

const NavLinks = ({ isLoggedIn, handleSignOut }) => {
  return (
    <Links>
      {/* <NavLink href="https://mastodon.stackingtabs.com/@stackingtabs"></NavLink> */}
      <NavLink href="https://mastodon.social/@stackingtabs">
        <FaMastodon />
      </NavLink>
      <NavLink href="https://github.com/prolenodev">
        <FaGithub />
      </NavLink>
      {/* <NavLink href="https://twitter.com/stackingtabs">
        <FaTwitter />
      </NavLink> */}
      {isLoggedIn ? (
        <>
          <NavLink href="/#/dashboard">
            <FaUserCircle />
          </NavLink>
          <AuthenticationButton onClick={handleSignOut}>
            Logout
          </AuthenticationButton>
        </>
      ) : (
        <NavLink href="/#/login">
          <AuthenticationButton>Login</AuthenticationButton>
        </NavLink>
      )}
    </Links>
  );
};

const AuthenticationButton = styled.button`
  font-weight: bold;
  color: white;
  background-color: #5999ad;
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 5px;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);

  &:hover {
    background-color: #0062cc;
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const NavLink = styled.a`
  margin-left: 20px;
  color: #5999ad;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #007bff;
    transform: scale(1.1);
  }
`;

const SideHeader = styled.span`
  @media screen and ${devices.mobileS} {
    display: flex;
    justify-content: center;
    align-self: center;
  }

  @media screen and ${devices.laptop} {
    grid-area: sideheader;
    grid-column: 5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const Header = (props) => {
  const { toggleTheme } = props;
  const { currentUser, logout } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [currentUser]);

  const handleSignOut = async () => {
    try {
      await logout();
      localStorage.removeItem("user");
      toast.success("Signed out.", { position: "top-center" });
      navigate("/");
    } catch (error) {
      // Handle sign out error
      console.log(error);
    }
  };

  return (
    <Wrapper>
      <TitleHeader>
        <LogoContainer to="">
          <Logo src={StackingTabsLogo} alt="Stacking Tabs Logo" />
          <Name>Stacking Tabs</Name>
        </LogoContainer>
        <Slogan>...playground for my mind...</Slogan>
      </TitleHeader>
      <SideHeader>
        <DarkModeToggle toggleTheme={toggleTheme} />
        {/* <NavLinks /> */}
        <NavLinks isLoggedIn={isLoggedIn} handleSignOut={handleSignOut} />
      </SideHeader>
    </Wrapper>
  );
};

export default Header;
