// import React, { useState } from 'react';
// import { AiOutlineHeart } from 'react-icons/ai';
// import styled from 'styled-components';

// const HeartButton = styled.button`
//   position: relative;
//   background: transparent;
//   border: none;
//   cursor: pointer;
//   outline: none;
//   padding: 0;
// `;

// const HeartIcon = styled(AiOutlineHeart)`
//   width: 24px;
//   height: 24px;
// `;

// const Count = styled.span`
//   position: absolute;
//   top: -10px;
//   right: -10px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 20px;
//   height: 20px;
//   border-radius: 50%;
//   background-color: #ff5252; /* Replace with your desired background color */
//   color: white;
//   font-size: 12px;
//   font-weight: bold;
// `;

// const LikeButton = () => {
//   const [count, setCount] = useState(0);

//   const handleClick = () => {
//     setCount(count + 1);
//   };

//   return (
//     <HeartButton onClick={handleClick}>
//       <HeartIcon />
//       <Count>{count}</Count>
//     </HeartButton>
//   );
// };

// export default LikeButton;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { auth } from "./authentication/FirebaseUtils";
import { toast } from "react-toastify";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const jiggle = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const StyledLikeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  padding: 0;
  font-size: 16px;
  color: ${({ liked }) => (liked ? "#ff4f70" : "#888888")};
  animation: ${fadeIn} 0.3s ease-in-out;
  transition: transform 0.3s;

  &:active {
    animation: ${jiggle} 0.5s;
  }
`;

const StyledLikesCount = styled.span`
  margin-left: 8px;
  font-size: 14px;
  color: #888888;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

const LikeButton = ({ pageId }) => {
  const [liked, setLiked] = useState(null);
  const [likesCount, setLikesCount] = useState(null);
  const [userIds, setUserIds] = useState([]);
  const [firebaseUserIds, setFirebaseUserIds] = useState([]);

  const user = auth.currentUser;
  const userId = user ? user.uid : null;

  useEffect(() => {
    fetchLikesData();
  }, []);

  // const fetchLikesData = async () => {
  //   try {
  //     const likesDataResponse = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/blog/${pageId}/get-likes-data`
  //     );
  //     setLikesCount(likesDataResponse.data.likes_count);

  //     if (user && user.uid) {
  //       const idToken = await user.getIdToken();
  //       const userLikesDataResponse = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/blog/${pageId}/get-likes-user-data`,
  //         {
  //           headers: {
  //             Authorization: `JWT ${idToken}`,
  //           },
  //         }
  //       );
  //       setUserIds(userLikesDataResponse.data.django_user_ids);
  //       setFirebaseUserIds(userLikesDataResponse.data.firebase_user_ids);
  //       if (firebaseUserIds) {
  //         const userLiked = firebaseUserIds.includes(userId);
  //         setLiked(userLiked);
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const fetchLikesData = async () => {
    try {
      const likesDataResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/blog/${pageId}/get-likes-data`
      );
      setLikesCount(likesDataResponse.data.likes_count);

      if (user && user.uid) {
        const userLikesDataResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/blog/${pageId}/get-likes-user-data`,
          {
            headers: {
              Authorization: `JWT ${await user.getIdToken()}`,
            },
          }
        );
        const { django_user_ids, firebase_user_ids } =
          userLikesDataResponse.data;
        if (firebase_user_ids.includes(userId)) {
          setLiked(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const fetchLikesData = async () => {
  //   try {
  //     const idToken = await user.getIdToken();

  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/blog/${pageId}/get-likes-data`,
  //       {
  //         withCredentials: true,
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `JWT ${idToken}`,
  //         },
  //       }
  //     );
  //     const { likes_count, django_user_ids, firebase_user_ids } = response.data;
  //     setLikesCount(likes_count);

  //     console.log('idTokenis', idToken)

  //     if (firebase_user_ids) {
  //       const userLiked = firebase_user_ids.includes(userId);
  //       setLiked(userLiked);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleLike = async () => {
    if (!user) {
      toast.info("Sign in to save", {
        position: "top-center",
      });
      return;
    }

    try {
      const idToken = await user.getIdToken();
      const method = liked ? "DELETE" : "POST";

      const response = await axios.request({
        method: method,
        url: `${process.env.REACT_APP_API_URL}/blog/${pageId}/like/`,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${idToken}`,
        },
      });

      setLiked(!liked);
      setLikesCount(response.data.likes);
    } catch (error) {
      setLiked(!liked);
      console.error(error);
    }
  };

  if (likesCount === null) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <StyledLikeButton liked={liked} onClick={handleLike}>
        {liked ? <AiFillHeart /> : <AiOutlineHeart />}
        <StyledLikesCount>{likesCount}</StyledLikesCount>
      </StyledLikeButton>
    </div>
  );
};

export default LikeButton;

// -------------------------------------
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { auth } from "./authentication/FirebaseUtils";
// import { toast } from "react-toastify";
// import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
// import styled, { keyframes } from "styled-components";

// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;

// const jiggle = keyframes`
//   0% {
//     transform: scale(1);
//   }
//   50% {
//     transform: scale(1.2);
//   }
//   100% {
//     transform: scale(1);
//   }
// `;

// const StyledLikeButton = styled.button`
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   border: none;
//   background-color: transparent;
//   cursor: pointer;
//   outline: none;
//   padding: 0;
//   font-size: 16px;
//   color: ${({ liked }) => (liked ? "#ff4f70" : "#888888")};
//   animation: ${fadeIn} 0.3s ease-in-out;
//   transition: transform 0.3s;

//   &:active {
//     animation: ${jiggle} 0.5s;
//   }
// `;

// const StyledLikesCount = styled.span`
//   margin-left: 8px;
//   font-size: 14px;
//   color: #888888;
//   animation: ${fadeIn} 0.3s ease-in-out;
// `;

// const LikeButton = ({ pageId }) => {
//   const [liked, setLiked] = useState(null);
//   const [likesCount, setLikesCount] = useState(null);

//   const user = auth.currentUser;
//   const userId = user ? user.uid : null;

//   useEffect(() => {
//     fetchLikesData();
//   }, []);

//   const fetchLikesData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/blog/${pageId}/get-likes-data`
//       );
//       const { likes_count, django_user_ids, firebase_user_ids } = response.data;
//       setLikesCount(likes_count);

//       const userLiked = firebase_user_ids.includes(userId);
//       setLiked(userLiked);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleLike = async () => {
//     if (!user) {
//       toast.info("Sign in to save", {
//         position: "top-center",
//       });
//       return;
//     }

//     let idToken = await user.getIdToken(true);
//     let method = liked ? "DELETE" : "POST";

//     try {
//       const response = await axios.request({
//         method: method,
//         url: `${process.env.REACT_APP_API_URL}/blog/${pageId}/like/`,
//         withCredentials: true,
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `JWT ${idToken}`,
//         },
//       });

//       setLiked(!liked);
//       setLikesCount(response.data.likes);
//     } catch (error) {
//       setLiked(!liked);
//       console.error(error);
//     }
//   };

//   if (likesCount === null || liked === null) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <StyledLikeButton liked={liked} onClick={handleLike}>
//         {liked? <AiFillHeart /> : <AiOutlineHeart /> }
//         <StyledLikesCount>{likesCount}</StyledLikesCount>
//       </StyledLikeButton>
//     </div>
//   );
// };

// export default LikeButton;

// ----------------------------------

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { auth } from "./authentication/FirebaseUtils";
// import { toast } from "react-toastify";

// const LikeButton = ({ pageId }) => {
//   const [liked, setLiked] = useState(null);
//   const [likesCount, setLikesCount] = useState(null);
//   // const [firebaseUserIds, setFirebaseUserIds] = useState([]);

//   const user = auth.currentUser;
//   const userId = user ? user.uid : null; // Get the Firebase user ID

//   useEffect(() => {
//     fetchLikesData();
//   }, []);

//   const fetchLikesData = async () => {
//     try {
//       // Fetch the likes data
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/blog/${pageId}/get-likes-data`
//       );
//       const { likes_count, django_user_ids, firebase_user_ids } = response.data;
//       setLikesCount(likes_count);
//       // setFirebaseUserIds(firebase_user_ids);

//       // Check if the current user's Django user ID exists in the array
//       const userLiked = firebase_user_ids.includes(userId);
//       setLiked(userLiked);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleLike = async () => {
//     if (!user) {
//       // User is not signed in, show error message
//       toast.info("Sign in to save", {
//         position: "top-center",
//       });
//       return;
//     }

//     let idToken = await user.getIdToken(true);
//     let method = liked ? "DELETE" : "POST";

//     try {
//       const response = await axios.request({
//         method: method,
//         url: `${process.env.REACT_APP_API_URL}/blog/${pageId}/like/`,
//         withCredentials: true,
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `JWT ${idToken}`,
//         },
//       });

//       setLiked(!liked);
//       setLikesCount(response.data.likes);
//     } catch (error) {
//       setLiked(!liked);
//       console.error(error);
//     }
//   };

//   if (likesCount === null || liked === null) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <button onClick={handleLike}>{liked ? "Unlike" : "Like"}</button>
//       <span>{likesCount} likes</span>
//     </div>
//   );
// };

// export default LikeButton;

// ----------------------------------

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { auth } from "./authentication/FirebaseUtils";

// // const LikeButton = ({ pageId }) => {
// //   const [liked, setLiked] = useState(false);
// //   const [likesCount, setLikesCount] = useState(0);
// //   const user = auth.currentUser;

// //   // useEffect(() => {
// //   //   axios
// //   //     .get(
// //   //       `${process.env.REACT_APP_API_URL}/api/v2/pages/?child_of=7&type=blog.Blog&fields=likes`
// //   //     )
// //   //     .then((response) => setLikesCount(response.data.likes_count))
// //   //     .catch((error) => console.error(error));
// //   // }, [pageId]);

// //   useEffect(() => {
// //     // Fetch the initial count and liked status from the server
// //     const fetchLikesCount = async () => {
// //       try {
// //         const response = await axios.get(
// //           `${process.env.REACT_APP_API_URL}/api/v2/pages/?child_of=7&type=blog.Blog&fields=likes`
// //         );
// //         setLikesCount(response.data.likes_count);
// //         setLiked(response.data.liked);
// //       } catch (error) {
// //         console.error(error);
// //       }
// //     };

// //     fetchLikesCount();
// //   }, [pageId]);

// //   // const handleLike = async () => {
// //   //   let idToken = await user.getIdToken(true);
// //   //   axios
// //   //     .post(
// //   //       `${process.env.REACT_APP_API_URL}/blog/` + pageId + `/like/`,
// //   //       {},
// //   //       {
// //   //         withCredentials: true,
// //   //         headers: {
// //   //           "Content-Type": "application/json",
// //   //           Authorization: `JWT ${idToken}`,
// //   //         },
// //   //       }
// //   //     )
// //   //     .then((response) => {
// //   //       setLiked(response.data.message === "Liked");
// //   //       setLikesCount(response.data.likes_count);
// //   //     })
// //   //     .catch((error) => console.error(error));
// //   // };

// //   // const handleLike = async () => {
// //   //   let idToken = await user.getIdToken(true);
// //   //   const url = `${process.env.REACT_APP_API_URL}/blog/${pageId}/like/`;

// //   //   try {
// //   //     if (liked) {
// //   //       // If already liked, send a DELETE request to unlike
// //   //       await axios({
// //   //         method: "delete",
// //   //         url: url,
// //   //         withCredentials: true,
// //   //         headers: {
// //   //           "Content-Type": "application/json",
// //   //           Authorization: `JWT ${idToken}`,
// //   //         },
// //   //       });
// //   //     } else {
// //   //       // If not liked, send a POST request to like
// //   //       await axios({
// //   //         method: "post",
// //   //         url: url,
// //   //         withCredentials: true,
// //   //         headers: {
// //   //           "Content-Type": "application/json",
// //   //           Authorization: `JWT ${idToken}`,
// //   //         },
// //   //       });
// //   //     }

// //   //     // Toggle the liked state
// //   //     setLiked(!liked);

// //   //     // Update the likes count accordingly
// //   //     setLikesCount((prevLikesCount) => {
// //   //       if (liked) {
// //   //         return prevLikesCount - 1; // Decrement the count for unliking
// //   //       } else {
// //   //         return prevLikesCount + 1; // Increment the count for liking
// //   //       }
// //   //     });
// //   //   } catch (error) {
// //   //     console.error(error);
// //   //   }
// //   // };

// //   // const handleLike = async () => {
// //   //   let idToken = await user.getIdToken(true);
// //   //   let method = liked ? 'DELETE' : 'POST';  // Use DELETE if already liked, otherwise use POST

// //   //   axios
// //   //     .request({
// //   //       method: 'DELETE',
// //   //       url: `${process.env.REACT_APP_API_URL}/blog/${pageId}/like/`,
// //   //       withCredentials: true,
// //   //       headers: {
// //   //         'Content-Type': 'application/json',
// //   //         Authorization: `JWT ${idToken}`,
// //   //       },
// //   //     })
// //   //     .then((response) => {
// //   //       setLiked(!liked);  // Toggle the liked state
// //   //       setLikesCount(response.data.likes);
// //   //       console.log('checking here')
// //   //     })
// //   //     .catch((error) => console.error(error));
// //   // };

// //   const handleLike = async () => {
// //     let idToken = await user.getIdToken(true);
// //     let method = liked ? 'DELETE' : 'POST';  // Use DELETE if already liked, otherwise use POST

// //     try {
// //       const response = await axios.request({
// //         method: method,
// //         url: `${process.env.REACT_APP_API_URL}/blog/${pageId}/like/`,
// //         withCredentials: true,
// //         headers: {
// //           'Content-Type': 'application/json',
// //           Authorization: `JWT ${idToken}`,
// //         },
// //       });

// //       setLiked(!liked);  // Toggle the liked state
// //       setLikesCount(response.data.likes);
// //       console.log('checking here');
// //     } catch (error) {
// //       console.error(error);
// //       console.log('checking here');
// //       setLiked(!liked);  // Toggle the liked state, as liking the post before gives 400 Bad Request error
// //       // setLikesCount(response.data.likes);
// //     }
// //   };

// //   return (
// //     <div>
// //       <button onClick={handleLike}>{liked ? "Unlike" : "Like"}</button>
// //       <span>{likesCount} likes</span>
// //       {console.log("likesCount:", likesCount)}
// //     </div>
// //   );
// // };

// // export default LikeButton;

// const LikeButton = ({ pageId }) => {
//   const [liked, setLiked] = useState(false);
//   const [likesCount, setLikesCount] = useState("");
//   // const [likesCount, setLikesCount] = useState(() => {
//   //   const storedLikesCount = localStorage.getItem(`likesCount_${pageId}`);
//   //   return storedLikesCount !== 0 ? parseInt(storedLikesCount) : null;
//   // });
//   const [loading, setLoading] = useState(true); // Add loading state
//   const user = auth.currentUser;

//   useEffect(() => {
//     let isMounted = true;

//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}/api/v2/pages/?child_of=7&type=blog.Blog&fields=likes&id=${pageId}/`
//         );

//         const likes = response.data.items[0]?.likes || 0;

//         if (isMounted) {
//           setLikesCount(likes);
//           setLoading(false);
//         }
//       } catch (error) {
//         console.error(error);

//         if (isMounted) {
//           setLoading(false);
//         }
//       }
//     };

//     fetchData();

//     return () => {
//       isMounted = false;
//     };
//   }, [pageId]);

//   // useEffect(() => {
//   //   const fetchLikesCount = async () => {
//   //     try {
//   //       const response = await axios.get(
//   //         `${process.env.REACT_APP_API_URL}/api/v2/pages/?child_of=7&type=blog.Blog&fields=likes&id=${pageId}/`
//   //       );

//   //       // Extract the likes count from the response
//   //       const likes = response.data.items[0]?.likes || null;
//   //       setLikesCount(likes);
//   //       setLoading(false); // Mark loading as false after successful fetch
//   //     } catch (error) {
//   //       console.error(error);
//   //       setLoading(false); // Mark loading as false even if fetch fails
//   //     }
//   //   };

//   //   fetchLikesCount();
//   // }, [pageId]);

//   const handleLike = async () => {
//     let idToken = await user.getIdToken(true);
//     let method = liked ? 'DELETE' : 'POST';  // Use DELETE if already liked, otherwise use POST

//     try {
//       const response = await axios.request({
//         method: method,
//         url: `${process.env.REACT_APP_API_URL}/blog/${pageId}/like/`,
//         withCredentials: true,
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `JWT ${idToken}`,
//         },
//       });

//       setLiked(!liked);  // Toggle the liked state
//       setLikesCount(response.data.likes);
//       // localStorage.setItem(`likesCount_${pageId}`, likesCount); // Store likesCount in local storage
//       console.log('checking here');
//     } catch (error) {
//       console.error(error);
//       setLiked(!liked);
//       // localStorage.setItem(`likesCount_${pageId}`, likesCount); // Store likesCount in local storage
//       // Handle the error and update the state accordingly
//       // You can set an error state and display an error message to the user
//     }
//   };

//   // Render loading state while fetching likesCount
//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <button onClick={handleLike}>{liked ? 'Unlike' : 'Like'}</button>
//       {loading == false && likesCount !== "" && <span>{likesCount} likes</span>}
//       {console.log('likesCount:', likesCount)}
//     </div>
//   );
// };

// export default LikeButton;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { auth } from "./authentication/FirebaseUtils";

// const LikeButton = ({ pageId }) => {
//   const [liked, setLiked] = useState(false);
//   const [likesCount, setLikesCount] = useState(null); // Initialize likesCount as null

//   const user = auth.currentUser;

//   useEffect(() => {
//     // Fetch likesCount when component mounts
//     fetchLikesCount();
//   }, []);

//   const fetchLikesCount = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/api/v2/pages/?child_of=7&type=blog.Blog&fields=likes&id=${pageId}`
//       );
//       const blog = response.data.items[0];
//       setLikesCount(blog.likes);
//       setLiked(blog.liked_by_user); // Set liked state based on server data
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleLike = async () => {
//     let idToken = await user.getIdToken(true);
//     let method = liked ? "DELETE" : "POST";

//     try {
//       const response = await axios.request({
//         method: method,
//         url: `${process.env.REACT_APP_API_URL}/blog/${pageId}/like/`,
//         withCredentials: true,
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `JWT ${idToken}`,
//         },
//       });

//       setLiked(!liked);
//       setLikesCount(response.data.likes);
//     } catch (error) {
//       console.error(error);
//       setLiked(!liked);
//     }
//   };

//   // Display loading until likesCount is fetched
//   if (likesCount === null) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <button onClick={handleLike}>{liked ? "Unlike" : "Like"}</button>
//       <span>{likesCount} likes</span>
//     </div>
//   );
// };

// export default LikeButton;
