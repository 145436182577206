import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import { lightTheme, darkTheme } from "./components/theme";
import { SearchContextProvider } from "./contexts/SearchContext";
import { AuthProvider } from "./contexts/AuthContext";
import AuthorizationForm from "./components/authentication/Auth";
import Dashboard from "./components/authentication/Dashboard";
import PrivateRoute from "./components/authentication/PrivateRoute";

import { devices } from "./components/Device";
import BlogList from "./components/BlogList";
import BlogPage from "./components/BlogPage";
import BlogListDev from "./components/BlogListDev";
import BlogPageDev from "./components/BlogPageDev";
import BlogListKorean from "./components/BlogListKorean";
import BlogPageKorean from "./components/BlogPageKorean";
import Header from "./components/Header";
import LandingPage from "./components/LandingPage";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactGA from "react-ga4";

// ReactGA.initialize("G-9PBXCV8MQW");
ReactGA.initialize("G-H4BY1HX2DX");

const GlobalStyle = createGlobalStyle`
  body {
    color: #5999ad;
  }
`;

const Wrapper = styled.div`
  // background-color: ${(props) =>
    props.isDarkMode
      ? props.theme.colors.background
      : props.theme.colors.background};
  // background-color: ${(props) => props.theme.colors.background};
  background-color: ${({ isDarkMode }) =>
    isDarkMode ? darkTheme.colors.background : lightTheme.colors.background};
  // // border: 1px grey solid;
  // // background-color: yellow;
  box-sizing: border-box;
  min-height: 100vh;
  // font-size: calc(1.375rem);
  // font-family: "Delius";
  // color: #5999ad;
  // // width: 100%;
  // // min-width: 100%;
  // // max-width: 100%;
  // // width: 50px;
  // // height: auto;
  // // overflow-x: hidden;
  // padding: 5px;

  // display: grid;
  // grid-template-columns: 1fr 3fr 1fr;
  // grid-template-areas: "leftsidebar center rightsidebar";
  // grid-gap: 0.25rem;

  // // @media (max-width: 1020px) {
  // //   display: flex;
  // //   transform: scale(0.95);
  // //   transform-origin: top center;
  // // }

  // // // @media screen and ${devices.tablet} {
  // // //   // display: flex;
  // // //   // transform: scale(0.6);
  // // //   // transform-origin: top center;
  // // // }
`;

const Leftsidebar = styled.div``;
const Center = styled.div`
  // border: 1px pink solid;
`;

const Content = styled.div``;

const Rightsidebar = styled.div``;

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };
  return (
    <div>
      <AuthProvider>
        <SearchContextProvider>
          <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <GlobalStyle />
            <Wrapper isDarkMode={isDarkMode}>
              <ToastContainer />
              <Leftsidebar />
              <Center>
                <Header toggleTheme={toggleTheme} />
                {/* <LandingPage /> */}
                <Routes>
                  <Route exact path="/login" element={<AuthorizationForm />} />
                  <Route element={<PrivateRoute />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                  </Route>
                  <Route path="/" element={<BlogList />} />
                  <Route exact path="/blog" element={<BlogList />} />
                  <Route exact path="/blog/:id" element={<BlogPage />} />
                  <Route
                    exact
                    path="/blog/korean"
                    element={<BlogListKorean />}
                  />
                  <Route
                    exact
                    path="/blog/korean/:id"
                    element={<BlogPageKorean />}
                  />
                  <Route exact path="/blog/dev" element={<BlogListDev />} />
                  <Route exact path="/blog/dev/:id" element={<BlogPageDev />} />
                </Routes>
              </Center>
              <Rightsidebar />
            </Wrapper>
          </ThemeProvider>
        </SearchContextProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
