import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { devices } from "./Device";
import GridList from "./GridList";
import SearchContext from "../contexts/SearchContext";
import { ReactComponent as AppMockup } from "../assets/images/stacking-tabs-app-mockup.svg";
import LikeButton from "./LikeButton";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const StyledAppMockup = styled(AppMockup)`
  max-width: 600px;
  display: block;
  margin: 0 auto;
`;

const BlogBlock = styled.div`
  // border: 1px green solid;
  // background-color: yellow;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch; /* Stretch the content to occupy the full width */
  flex-grow: 1; /* Allow the content to grow if necessary */
`;
const BlogCard = styled.div`
  border: 1px cyan solid;
  //   background-color: red;
  display: flex;
  flex-direction: ${(props = true) => (props.isGrid ? "column" : "row")};
  padding: 10px;
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  box-shadow: 2px 3px 36px 8px rgba(0, 0, 0, 0.1);
  transition-duration: 1s;
  transform-origin: left;
  align-items: flex-end; /* Align like button to the right */
  font-size: 1.2rem;
  text-decoration: none;
  font-size: 0.6rem;
  width: ${(props = true) => (props.isGrid ? "25vw" : "80vw")};
  height: ${(props = true) => (props.isGrid ? "40vw" : "30vw")};
  text-align: center;

  &:hover {
    background: rgba(89, 153, 173, 0.52);
    background: linear-gradient(
      90deg,
      rgba(53, 54, 58, 1) 0%,
      rgba(89, 153, 173, 1) 50%,
      rgba(53, 54, 58, 1) 100%
    );
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10.7px);
    -webkit-backdrop-filter: blur(10.7px);
    border: 1px solid rgba(89, 153, 173, 0.79);
  }

  @media screen and ${devices.laptop} {
    // width: 300px;
    // height: 300px;
    width: ${(props = true) => (props.isGrid ? "300px" : "50vw")};
    height: ${(props = true) => (props.isGrid ? "300px" : "25vw")};
    min-height: ${(props = true) => (props.isGrid ? "300px" : "10vw")};
    max-height: ${(props = true) => (props.isGrid ? "300px" : "10vw")};
    font-size: 1.2rem;

    &:hover {
      box-shadow: 2px 3px 36px 8px rgba(0, 0, 0, 0.4);
      max-height: 80vw;
      z-index: 2;
    }
  }
`;

const BlogImage = styled.img`
  margin: 5px;
  border-radius: 5px;
  height: ${(props) => (props.isGrid ? "auto" : "100%")};
  min-height: calc(
    100% - 500px
  ); /* subtract the height of the title and description */
  max-width: 300px;
  object-fit: ${(props) =>
    props.isGrid ? "cover" : "contain"}; /* use contain for list view */
  margin-top: 5px;

  @media (max-width: 1080px) {
    max-width: 150px;
  }
`;

const linkStyle = {
  textDecoration: "none",
  color: "#5999ad",
};

const Grid = styled.div`
  display: ${(props = true) => (props.isGrid ? "grid" : "block")};
  grid-template-columns: auto auto auto;
  transition-duration: 0.1s;
`;

const List = styled.div``;

const Title = styled.h2`
  font-weight: bold;
  font-size: calc(12px + 1vw);
  text-align: center;
  margin: 0 auto;
  max-width: 80%;
  line-height: 1.8rem;

  @media (max-width: 768px) {
    font-size: calc(10px + 2vw);
    line-height: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: calc(8px + 2vw);
    line-height: 1rem;
  }
`;

const Description = styled.p`
  font-size: 0.8rem;
  text-align: center;
  margin: 0 auto;
  max-width: 80%;
  line-height: 0.8rem;

  @media (max-width: 768px) {
    /* Desktop styles */
    font-size: 0.8rem;
    line-height: 0.8rem;
  }

  @media (max-width: 480px) {
    /* Mobile styles */
    font-size: 0.6rem;
    line-height: 0.6rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const BlogListKorean = () => {
  const [blog, setBlog] = useContext(SearchContext);
  // const [blog, setBlog] = useState([]); also can
  const [loading, setLoading] = useState(false);
  const [isGrid, setIsGrid] = useState(true);
  const [page, setPage] = useState(1);
  const [hasScrolled, setHasScrolled] = useState(false);
  const perPage = 20;

  const fetchBlogPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v2/pages/?child_of=7&type=blog.Blog&fields=description,thumbnail&order=-first_published_at&offset=${
          (page - 1) * perPage
        }&limit=${perPage}`
      );
      if (response.data.items.length === 0) {
        window.removeEventListener("scroll", handleScroll);
        return;
      }
      setBlog((prevBlog) => [...prevBlog, ...response.data.items]);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = async () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 1 &&
      !loading &&
      !hasScrolled
    ) {
      setLoading(true);
      setHasScrolled(true);
    }
  };

  useEffect(() => {
    fetchBlogPosts();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (hasScrolled) {
      fetchBlogPosts();
      setHasScrolled(false);
    }
  }, [hasScrolled]);

  if (loading && blog.length === 0) {
    return (
      <h4>
        <img src="https://i.pinimg.com/originals/48/6a/a0/486aa0fa1658b7522ecd8918908ece40.gif" />
      </h4>
    );
  }

  const handleGrid = () => {
    setIsGrid(true);
  };

  const handleList = () => {
    setIsGrid(false);
  };

  return (
    <BlogBlock>
      <Container>
        <a href="../assets/downloads/stabs-apk-version-1-0-0.apk" download>
          <StyledAppMockup alt="app-mockup" />
          {/* Download APK */}
        </a>
        <GridList isGrid={isGrid} setIsGrid={setIsGrid} />
        <BlogBlock>
          <Grid isGrid={isGrid}>
            {blog.map((blog) => (
              <List key={blog.id}>
                <BlogCard isGrid={isGrid}>
                  <LikeButton pageId={blog.id} />
                  <Link to={`/blog/${blog.id}`} style={linkStyle}>
                    <Content>
                      <Title>{blog.title}</Title>
                      <br />
                      <Description>{blog.description}</Description>
                    </Content>
                    <BlogImage
                      src={`${process.env.REACT_APP_API_URL}${blog.thumbnail.meta.download_url}`}
                      key={blog.id}
                      alt="thumbnail-image"
                    />
                  </Link>
                </BlogCard>
              </List>
            ))}
          </Grid>
        </BlogBlock>
      </Container>
    </BlogBlock>
  );
};

export default BlogListKorean;
