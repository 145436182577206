import React, { useContext, useState, useEffect } from "react";
import { auth } from "../components/authentication/FirebaseUtils";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login: (email, password) =>
      auth.signInWithEmailAndPassword(email, password),
    signup: (email, password) =>
      auth.createUserWithEmailAndPassword(email, password),
    logout: () => auth.signOut(),
    resetPassword: (email) => auth.sendPasswordResetEmail(email),
  };

  // const value = {
  //   currentUser,
  //   login: (email, password) => auth.signInWithEmailAndPassword(email, password),
  //   signup: async (email, password) => {
  //     try {
  //       const res = await auth.createUserWithEmailAndPassword(email, password);
  //       const user = res.user;
  //       console.log(res.user);

  //       await user.sendEmailVerification({ url: "http://localhost:3000/login" });
  //       await user.reload(); // Reload the user object to get the latest emailVerified status

  //       if (user.emailVerified) {
  //         let idToken = await user.getIdToken(true);

  //         const response = await fetch("http://localhost:8000/api/register", {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `JWT ${idToken}`,
  //           },
  //           body: JSON.stringify({ email: user.email }),
  //         });
  //         const data = await response.json();
  //         console.log(data);
  //       } else {
  //         console.log("User has not verified their email yet");
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       throw error;
  //     }
  //   },
  //   logout: () => auth.signOut(),
  //   resetPassword: email => auth.sendPasswordResetEmail(email)
  // };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
